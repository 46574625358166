import Label from './Label';
import uploadCloud from '../../assets/icons/upload-cloud.svg';

const FileUpload = ({label, subText}) => {
  return (
    <div>
      <Label>{label}</Label>
      {subText && <span className='block text-[13px] text-background-2 mb-2'>{subText}</span>}
      <div className="flex items-center justify-center rounded min-h-[92px] text-sm border border-grey-light text-grey-light">
        <label
          className="flex flex-col items-center gap-2 cursor-pointer"
          htmlFor="dropzoneFile"
        >
          <img src={uploadCloud} alt="" />
          <span className="underline">Drag and Drop files, or Browse</span>
        </label>
        <input
          className="hidden"
          type="file"
          accept=".png,jpg"
          id="dropzoneFile"
        />
      </div>
    </div>
  );
};

export default FileUpload;
