import React from "react";
import { useNavigate } from "react-router-dom";
const Logout = () => {
  const navigate = useNavigate();

  const refreshPage = () => {
    return navigate(0);
  };
  React.useEffect(() => {
    localStorage.clear();
    refreshPage();
  });
  return <></>;
};

export default Logout;
