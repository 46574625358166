import pdf from "../assets/icons/pdf.svg";
import excel from "../assets/icons/excel.svg";

const RecentProjects = [
  {
    image: pdf,
    appName: "Logistics App",
    members: "7 Members",
    date: "Sep 22, 2020",
    size: "3MB",
  },
  {
    image: excel,
    appName: "Banking App",
    members: "5 Members",
    date: "Jun 18, 2019",
    size: "2MB",
  },
  {
    image: pdf,
    appName: "Health Care App",
    members: "Just You",
    date: "Dec 07, 2022",
    size: "10MB",
  },
];

const Stories = [
  {
    id: 5,
    storyId: "DR01",
    title: "Embedded systems App.",
    content:
      "As a user, I want to be able to add details of my regular payment to my mobile app, so that they can be automated. ra",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#6335A6]",
  },
  {
    id: 6,
    storyId: "DR01",
    title: "Cosmetic App.",
    content:
      "As a user, I want to be able to add details of my regular payment to my mobile app, so that they can be automated. d",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#004FC4]",
  },
  {
    id: 7,
    storyId: "DR01",
    title: "Industry  App.",
    content:
      "As a user, I want to be able to add details of my regular payment to my mobile app, so that they can be automated. o",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#05A660]",
  },
  {
    id: 8,
    storyId: "DR01",
    title: "Food App.",
    content:
      "As a user, I want to be able to add details of my regular payment to my mobile app, so that they can be automated. m",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#E5B800]",
  },
  {
    id: 9,
    storyId: "DR01",
    title: "Delivery App.",
    content:
      "As a user, I want to be able to add details of my regular payment to my mobile app, so that they can be automated. n",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#E53535]",
  },
];

export const SprintTasks = [
  {
    id: 1,
    title: "Project X 2022",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl amet nunc nec quam...",
    date: "Oct 20, 2022",
  },
  {
    id: 2,
    title: "Project X 2022",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl amet nunc nec quam...",
    date: "Oct 20, 2022",
  },
];

const Tasks = [
  {
    id: 1,
    icon: "⭕️",
    status: "To Do",
    title: "Human Interest Form",
    content: "Fill out human interest distribution form",
    storyId: "DR01",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#E5B800]",
  },
  {
    id: 2,
    icon: "⭕️",
    status: "To Do",
    title: "Purchase present",
    content: "Get an anniversary gift",
    storyId: "DR01",
    criteria: "Page Opens",
    date: "30/08/2022",
    bgColor: "bg-[#E5B800]",
  },
  {
    id: 3,
    icon: "⭕️",
    status: "To Do",
    title: "Invest in investments",
    content: "Call the bank to talk about investments",
    storyId: "DR01",
    date: "30/08/2022",
  },
  {
    id: 4,
    icon: "⭕️",
    status: "To Do",
    title: "Daily reading",
    content: "Finish reading Intro to UI/UX",
    storyId: "DR01",
    bgColor: "bg-[#E5B800]",
  },
];

const StatusIcons = [
  {
    status: "To Do",
    icon: "⭕️",
  },
  {
    status: "Doing",
    icon: "🔆️",
  },

  {
    status: "Done",
    icon: "✅",
  },
];

const bgColors = [
  {
    id: 0,
    color: "bg-[#6335A6]",
  },
  {
    id: 1,
    color: "bg-[#004FC4]",
  },
  {
    id: 2,
    color: "bg-[#05A660]",
  },
  {
    id: 3,
    color: "bg-[#E5B800]",
  },
  {
    id: 4,
    color: "bg-[#E53535]",
  },
];

export { RecentProjects, Tasks, StatusIcons, Stories, bgColors };
