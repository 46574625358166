const LinearProgress = ({ progressPercentage }) => {
  return (
    <div className="h-1 w-full bg-gray-300">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full ${
          progressPercentage < 50
            ? 'bg-[#EC2828]'
            : progressPercentage < 70
            ? 'bg-[#F2AF03]'
            : 'bg-[#07E01D]'
        }`}
      ></div>
    </div>
  );
};

export default LinearProgress;
