const DropWrapper = ({ onDrop, children, progressId, ...rest }) => {
  const allowDrop = (e) => e.preventDefault();
  const handleDrop = (e) => {
    const data = JSON.parse(e.dataTransfer.getData("item"));
    onDrop(data, progressId);
  };
  return (
    <div
      className="drop-wrapper"
      onDragOver={allowDrop}
      onDrop={handleDrop}
      {...rest}
    >
      {children}
    </div>
  );
};

export default DropWrapper;
