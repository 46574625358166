import React from "react";
import circle1 from "../../assets/icons/circle1.svg";
import circle2 from "../../assets/icons/circle2.svg";
import circle3 from "../../assets/icons/circle3.svg";

const CircleImage = () => {
  const tab = [
    { id: 0, url: circle1 },
    { id: 1, url: circle2 },
    { id: 2, url: circle3 },
  ];
  return (
    <div className="flex  items-center">
      {tab.map((props) => {
        return (
          <div className="ml-[-1rem]" key={props.id}>
            <img src={props.url} alt="no img" />
          </div>
        );
      })}
      <span className="h-[30px] ml-[-1rem] w-[30px] flex items-center justify-center rounded-full border-white border-[1px] bg-[#F5F5F5]">
        +4
      </span>
    </div>
  );
};

export default CircleImage;
