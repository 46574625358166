import { useState } from "react";
import {
  Card,
  Divider,
  Modal,
  Input,
  TextArea,
  Button,
  SelectField,
} from "../ui";
import Actions from "./Actions";
import { bgColors } from "../../constants/data";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const Story = ({ story, setDragElement, handleOpen, idx, editOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onDragStart = ({ dataTransfer, target }) => {
    dataTransfer.setData("item", JSON.stringify(story));
    // console.log("ON DRAG START", { dataTransfer: JSON.stringify(story) });
    setDragElement(story);
    setTimeout(() => {
      target.style.display = "hidden";
    }, 1);
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragEnd = (e) => (e.target.style.visibility = "visible");

  const handleMouseOver = (e, id) => {
    setAnchorEl(id);
  };

  const handleMouseLeave = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "actions" : undefined;

  return (
    <>
      <Card
        draggable="true"
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        onMouseOver={(e) => handleMouseOver(e, story?.uuid)}
        onMouseLeave={handleMouseLeave}
        className={`basis-64 shrink-0 min-h-[262px] ${bgColors[idx]?.color} text-white px-4 py-5 relative`}
      >
        <div className="flex items-center justify-between text-sm">
          <span>{story?.storyId}</span>
          <span>{story.date}</span>
        </div>
        <Divider className="my-2 border-t-[0.5px] border-white" />
        <span className="text-base font-bold">{story.title}</span>
        <p className="text-sm mt-2">{story?.story}</p>
        <Divider className="my-4 border-t-[0.5px] border-white" />
        <span className="block mb-1 font-bold">Acceptance Criteria</span>
        <span className="text-sm">{story?.acceptanceCriteria}</span>
        <Actions
          id={id}
          storyColor={bgColors[idx]?.color}
          handleOpen={handleOpen}
          editOpen={editOpen}
          story={story}
        />
      </Card>
    </>
  );
};

const Stories = ({ stories, setDragElement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({});

  const { project } = useSelector((state) => state.project);
  const handleOpen = (data) => {
    console.log("Opened");
    setIsOpen(true);
    setSelected(data);
    onSeleceted(data);
  };
  const editOpen = () => setOpen(true);

  function onSeleceted(data) {
    setValue("story", selected?.story);
    console.log(data);
  }

  const {
    register,
    // handleSubmit,
    // formState,
    setValue,
  } = useForm({
    defaultValues: {
      story: "",
      description: "",
      acceptanceCriteria: "",
    },
  });

  const sprints = project?.projectSprints;
  const selectOptions = sprints.map((sprint) => ({
    label: sprint.sprintTitle,
    value: sprint.sprintSlug,
  }));
  return (
    <>
      <>
        {stories?.map((story, index) => (
          <Story
            key={index}
            idx={index}
            story={story}
            setDragElement={setDragElement}
            handleOpen={handleOpen}
            editOpen={editOpen}
          />
        ))}
      </>
      <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <span className="text-background-2 text-2xl">Edit User Story</span>
        <div className="flex flex-col gap-3 mt-8">
          <div>
            <Input
              label="User Story Title"
              defaultValue="Banking App"
              name="story"
              register={register}
            />
          </div>
          <div>
            <TextArea
              label="Description"
              defaultValue="Lorem ipsum dolor sit amet consectetur. Maecenas malesuada convallis mauris amet cursus consectetur. Quis faucibus non aliquet sapien et mi. Adipiscing sit elementum sed orci at purus. "
              name="description"
              register={register}
            />
          </div>
          <div className="relative">
            <Input
              label="Acceptance Criteria"
              name="acceptanceCriteria"
              register={register}
            />
            <div className="absolute h-7 top-[55%] -translate-x-1/2 right-0">
              {/* <Button className="max-h-7 rounded-[2px]" variant="outlined">
                Add
              </Button> */}
            </div>
          </div>
          {/* <ul className="flex flex-col gap-2 mt-2">
            <li className="flex items-center gap-1">
              <span className="w-2 h-2 rounded-full bg-[#CBBCE1]"></span>
              <span className="font-light text-background-2 text-sm">
                Page Loads
              </span>
            </li>
            <li className="flex items-center gap-1">
              <span className="w-2 h-2 rounded-full bg-[#CBBCE1]"></span>
              <span className="font-light text-background-2 text-sm">
                Autentication Works
              </span>
            </li>
          </ul> */}
          <div className="w-[188px] h-{48px] my-8">
            <Button fullWidth>Update</Button>
          </div>
        </div>
      </Modal>

      {/* Add Sprint */}
      <Modal isOpen={open} handleClose={() => setOpen(false)}>
        <div>
          <SelectField
            id="sprintTitle"
            label="Choose sprint to add user story"
            options={selectOptions}
          />
        </div>
        <div className="w-[188px] h-{48px] my-8">
          <Button color="bg-primary" className="text-white" fullWidth>
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Stories;
