import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import UserDashboard from "./pages/UserDashboard";
import UserProjects from "./pages/Projects";
import CreateProject from "./pages/CreateProject";
import NewCreatedProject from "./pages/NewProject";
import NewProject from "./pages/UserStories";
import { UserLayout } from "./layouts";

import {
  CheckEmailPath,
  ForgotPasswordPath,
  LoginPath,
  NewPasswordPath,
  RegisterPath,
  ResetSuccessfulPath,
  UserAccountSetingsPath,
  UserAllSprintPath,
  UserDashboardPath,
  UserProjectsPath,
  UserCreateProjectPath,
  UserNewProjectPath,
  UserSprintPath,
  UserStoriesPath,
  userWalletPath,
  UserWorkspacePath,
  UserProductReviewPath,
  UserCreatePostPath,
} from "./constants/paths";
import ForgotPassword from "./pages/auth/ForgotPassword";
import NewPassword from "./pages/auth/NewPassword";
import CheckEmail from "./pages/auth/CheckEmail";
import ResetSuccessful from "./pages/auth/ResetSuccessful";
import SprintPlaning from "./pages/SprintPlaning";
import AllSprint from "./pages/AllSprint";
import Wallet from "./pages/Wallet";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import UnProtectedRoute from "./components/auth/UnprotectedRoute";
import AccountSettings from "./pages/AccountSettings";
import WorkspaceSettings from "./pages/WorkSpace";
import NotFound from "./pages/404";
import Logout from "./pages/Logout";
import ProductReview from "./pages/ProductReview";
import CreatePost from "./pages/CreatePost";

function App() {
  return (
    <>
      <Routes>
        <Route element={<UnProtectedRoute />}>
          <Route path={LoginPath} element={<Login />} />
          <Route path={RegisterPath} element={<Register />} />
          <Route path={ForgotPasswordPath} element={<ForgotPassword />} />
          <Route path={NewPasswordPath} element={<NewPassword />} />
          <Route path={CheckEmailPath} element={<CheckEmail />} />
          <Route path={ResetSuccessfulPath} element={<ResetSuccessful />} />
        </Route>
        {/* user dashboard routes starts here */}
        <Route
          element={
            <UserLayout>
              <ProtectedRoute />
            </UserLayout>
          }
        >
          <Route path="/logout" element={<Logout />} />
          <Route path={UserAccountSetingsPath} element={<AccountSettings />} />
          <Route path={UserWorkspacePath} element={<WorkspaceSettings />} />
          <Route path={UserDashboardPath} element={<UserDashboard />} />
          <Route path={UserProjectsPath} element={<UserProjects />} />
          <Route path={UserCreateProjectPath} element={<CreateProject />} />
          <Route path={UserNewProjectPath} element={<NewCreatedProject />} />
          <Route path={UserStoriesPath} element={<NewProject />} />
          <Route path={UserSprintPath} element={<SprintPlaning />} />
          <Route path={UserAllSprintPath} element={<AllSprint />} />
          <Route path={userWalletPath} element={<Wallet />} />
          <Route path={UserCreatePostPath} element={<CreatePost/>}/>
          <Route path={UserProductReviewPath} element={<ProductReview />}/>
        </Route>
        {/* user dasboard routes ends here */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
