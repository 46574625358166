import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

const UnprotectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      return navigate("/user/dashboard");
    }
  }, [navigate, token]);

  return <Outlet />;
};

export default UnprotectedRoute;
