import { NavLink } from "react-router-dom";
import { Divider } from "../../components/ui";

import { SidebarItems } from "../../constants/sidebar-items";

const Item = ({ icon, title, path }) => (
  <li className={`select-none mb-6`}>
    <NavLink
      to={path}
      className={`flex items-center rounded-lg pl-2 md:pl-4 lg:pl-8 py-[10px] lg:py-3 gap-6 text-xs sm:text-sm lg:text-base leading-6 text-grey-light font-light nav-link transition duration-0 hover:duration-300`}
    >
      <span>{icon}</span>
      <span
        className={`whitespace-pre`}
      >
        {title}
      </span>
    </NavLink>
  </li>
);
const Items = () => {
    return (
    <>
      <ul>
        {SidebarItems.slice(0, 1).map((item, index) => (
          <Item
            key={index + 1}
            icon={item.icon}
            title={item.title}
            path={item.path}
          />
        ))}
      </ul>
      <Divider className="hidden my-10 lg:block" />
      <ul>
        {SidebarItems.slice(1).map((item, index) => (
          <Item
            key={index + 1}
            icon={item.icon}
            title={item.title}
            path={item.path}
          />
        ))}
      </ul>
    </>
  );
};

export default Items;
