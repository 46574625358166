import React from "react";
import { Button } from "../ui";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../features/auth/authSlice";
import PasswordInput from "../ui/PasswordInput";

const ChangePassword = () => {
  const { register, handleSubmit, reset } = useForm();
  const {
    auth: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div className="w-[100%] pt-3 lg:w-[920px] mx-auto lg:mt-8 pb-5 mb-7 lg:py-6 bg-white pl-2 lg:pl-6">
      <p className="text-[24px] font-normal font-sans">Change Password</p>
      <div className="w-[90%] lg:w-[856px] mb-6 h-[0.5px] bg-[#CFCED1]"></div>
      <div className="flex flex-col lg:flex-row lg:flex-wrap gap-6">
        <div className="lg:w-[40%] w-[95%]">
          <PasswordInput
            label="Current password"
            type="text"
            placeholder="New password"
            register={register}
            name="currentPassword"
          />
        </div>
        <div className="lg:w-[40%] w-[95%]">
          <PasswordInput
            label="New Password"
            type="text"
            placeholder="Confirm password"
            register={register}
            name="newPassword"
          />
        </div>
      </div>
      <Button
        disabled={loading}
        size="large"
        color="bg-primary"
        className="text-white mt-10 bg-gradient-to-r from-primary to-primary-light mr-7"
        onClick={handleSubmit((data) => {
          console.log({ data });
          dispatch(updatePassword(data))
            .then(() => {})
            .finally(() => {
              reset();
            });
        })}
      >
        Update
      </Button>
    </div>
  );
};

export default ChangePassword;
