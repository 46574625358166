import google from '../assets/images/google.svg';
import twitter from '../assets/images/twitter.svg';
import linkedin from '../assets/images/linkedin.svg';
import img from '../assets/images/img.svg';
import mail from '../assets/images/mail.svg';
import check from '../assets/images/check.svg';
import image from '../assets/images/image.svg';
import arrowr from '../assets/images/arrowr.svg';
import arrowl from '../assets/images/arrowl.svg';
import arrowleft from '../assets/images/arrowleft.svg';
import cans from '../assets/images/cans.svg';

export const Google = (props) => {
    return <img src={google} alt="requs logo" {...props}  />
}

export const Twitter = (props) => {
    return <img src={twitter} alt="requs logo" {...props}  />
}

export const LinkedIn = (props) => {
    return <img src={linkedin} alt="requs logo" {...props}  />
}
export const Img = (props) => {
    return <img src={img} alt="requs logo" {...props}  />
}
export const Mail = (props) => {
    return <img src={mail} alt="requs logo" {...props}  />
}
export const Check = (props) => {
    return <img src={check} alt="requs logo" {...props}  />
}
export const Image = (props) => {
    return <img src={image} alt="requs logo" {...props}  />
}
export const ArrowLeft = (props) => {
    return <img src={arrowl} alt="requs logo" {...props}  />
}
export const ArrowRight = (props) => {
    return <img src={arrowr} alt="requs logo" {...props}  />
}
export const ArrowLe = (props) => {
    return <img src={arrowleft} alt="requs logo" {...props}  />
}
export const Cans = (props) => {
    return <img src={cans} alt="requs logo" {...props}  />
}
