import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  //redirect to login if no token is found in the redux store
  useEffect(() => {
    if (!token) {
      return navigate("/");
    }
  }, [navigate, token]);

  return <Outlet />;
};

export default ProtectedRoute;
