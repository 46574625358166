import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import chevrons from "../../assets/icons/chevrons.svg";

const defaultSeparator = (
  <img src={chevrons} className="" alt="no-img" width="16" height="16" />
);

const BreadCrumbs2 = ({ separator = defaultSeparator }) => {
  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter((x) => x && x !== "user");

  // useEffect(()=>{
  //   if (Paths.UserSprintPath) return onToggleSideNav
  // },[])

  const isLast = (index) => {
    return index === pathnames.length - 1;
  };
  if (pathnames.length === 1 && pathnames[0] === "dashboard") return null;

  return (
    <>
      <ol className="hidden list-reset sm:flex items-center text-base pb-6">
        <div className="flex w-full justify-between"></div>
      </ol>

      <ol className="hidden list-reset sm:flex items-center gap-3 text-base pt-4 pb-6">
        <li>
          <Link to="/user/dashboard" className="text-link text-[#999999]">
            Dashboard
          </Link>
        </li>
        {pathnames.map((name, index) => {
          if (name === "dashboard") return null;

          const activeLink = isLast(index);
          const updatedName = name.split("-").join(" ");

          return (
            <Fragment key={name}>
              <li>{separator}</li>
              {activeLink ? (
                <div className="flex justify-between w-[100%]">
                  <li className="text-background-2 capitalize">
                    {updatedName}
                  </li>
                </div>
              ) : (
                <li>
                  <Link
                    to={`/${name}`}
                    className="text-link capitalize  text-[#999999]"
                  >
                    {updatedName}
                  </Link>
                </li>
              )}
            </Fragment>
          );
        })}
      </ol>
    </>
  );
};

export default BreadCrumbs2;
