import React from "react";
import share from "../assets/icons/share.svg";
import arrowl from "../assets/icons/arrowl.svg";
import trash from "../assets/icons/trash.svg";
import vertical from "../assets/icons/vertical.svg";
import { Button } from "../components/ui";
import { Link } from "react-router-dom";
import { UserAllSprintPath } from "../constants/paths";
import Tabs from "../components/Tabs";
import CircleImage from "../components/ui/CircleImage";
const Wallet = () => {
  const Details = [
    {
      task: "Done",
      details: "Transaction History",
      asignee: "",
      piority: "Very High",
      date: "12th, Oct 2022",
    },
    {
      task: "Doing",
      details: "Withdrawal",
      asignee: "",
      piority: "Very High",
      date: "12th, Oct 2022",
    },
    {
      task: "Doing",
      details: "Transaction History",
      asignee: "",
      piority: "Low",
      date: "12th, Oct 2022",
    },
  ];

  const Tab=[
    {
      id:0,
      title:"Doing"
    },
    {
      id:1,
      title:"Done"
    },
    {
      id:2,
      title:"Pending"
    },
    {
      id:3,
      title:"Backlog"
    },
  ]

  return (
    <div className="mx-[-32px] my-6">
      <div className="flex justify-between mb-7">
        <Link to={UserAllSprintPath}>
          <div className="flex">
            <span className="pr-[12px]">
              <img src={arrowl} alt="no img" />
            </span>
            All my sprint
          </div>
        </Link>
        <div className="flex">
          <span className="flex items-center">
            <span className="pr-[12px] ">
              <img src={share} alt="no img" />
            </span>
            Share
          </span>
          <span className="flex items-center">
            <span className="pr-[12px] ml-3">
              <img src={trash} alt="no img" />
            </span>
            Remove Sprint
          </span>
        </div>
      </div>
      <div className="flex">
        <Button color="#F7F3FB" className="text-green-600 mr-5 bg-[#EBFFEB]">
          4 Active Task
        </Button>
        <CircleImage/>
      </div>
      <div className="mt-[26px] font-sans text-[20px] font-medium mb-9">
        Wallet features
      </div>
      <div>
        <Tabs list={Tab}/>
      </div>
      {Details.map((props) => {
        return (
          <div
            className="w-[1080px] h-[65px] m-auto flex justify-between bg-white items-center px-6 my-6"
            key={props.id}
          >
            <div>
              <span className="text-[12px] font-sans mr-4">{props.task}</span>
              <span className="text-[18px] font-sans font-bold">
                {props.details}
              </span>
            </div>
            <div className="flex items-center">
              <span className="flex">
                {" "}
                <span className="text-[12px] mt-2 font-sans mr-2">Assignee</span>
                <span className="mx-4"> <CircleImage/></span>
               
                {props.asignee}
              </span>
              <span className="text-[14px] font-bold font-sans mr-5">
                <span className="text-[12px] font-normal font-sans mr-2">
                  Piority
                </span>
                {props.piority}
              </span>
              <span className="text-[14px] font-bold font-sans mr-5">
                <span className="text-[12px] font-normal font-sans mr-2">
                  Date
                </span>
                {props.date}
              </span>
              <img src={vertical} alt="no img" />
            </div>
          </div>
        );
      })}
      <div className="flex justify-center mt-20">
        <Button color="red" className='bg-primary text-white'>Create New Sprint</Button>
      </div>
    </div>
  );
};

export default Wallet;
