import { SelectField as Select, Button, TextArea, Spinner } from "../ui";

const userActions = [
  { label: "As a System", value: "As a System" },
  { label: "As a Config", value: "As a Config" },
  { label: "As a Product Setup", value: "As a Product Setup" },
  { label: "As a Developer", value: "As a Developer" },
  { label: "As a Monitoring System", value: "As a Monitoring System" },
];

const iWants = [
  { label: "I want to create", value: "I want to create" },
  { label: "I want to develop", value: "I want to develop" },
  { label: "I want to build", value: "I want to build" },
  { label: "I want to plan", value: "I want to plan" },
  { label: "I want to research", value: "I want to research" },
  { label: "I want to test", value: "I want to test" },
  { label: "I want to manage", value: "I want to manage" },
  { label: "I want to find", value: "I want to find" },
  { label: "I want to approve", value: "I want to approve" },
  { label: "I want to moderate", value: "I want to moderate" },
  { label: "I want to monitor", value: "I want to monitor" },
  { label: "I want to use", value: "I want to use" },
  { label: "I want to know", value: "I want to know" },
  { label: "I want to update", value: "I want to update" },
  { label: "I want to get", value: "I want to get" },
  { label: "I want to search", value: "I want to search" },
  { label: "I want to increase", value: "I want to increase" },
  { label: "I want to remove", value: "I want to remove" },
  { label: "I want to change", value: "I want to change" },
  { label: "I want to provide", value: "I want to provide" },
  { label: "I want to send", value: "I want to send" },
];

const contexts = [
  { label: "content", value: "content" },
  { label: "account", value: "account" },
  { label: "information", value: "information" },
  { label: "document", value: "document" },
  { label: "material", value: "material" },
  { label: "instruction", value: "instruction" },
  { label: "platform", value: "platform" },
  { label: "portal", value: "portal" },
  { label: "website", value: "website" },
  { label: "users", value: "users" },
  { label: "money", value: "money" },
];

const soIcans = [
  { label: "so i can create", value: "so i can create" },
  { label: "so i can develop", value: "so i can develop" },
  { label: "so i can build", value: "so i can build" },
  { label: "so i can plan", value: "so i can plan" },
  { label: "so i can research", value: "so i can research" },
  { label: "so i can perform", value: "so i can perform" },
  { label: "so i can test", value: "so i can test" },
  { label: "so i can manage", value: "so i can manage" },
  { label: "so i can find", value: "so i can find" },
  { label: "so i can approve", value: "so i can approve" },
  { label: "so i can moderate", value: "so i can moderate" },
  { label: "so i can monitor", value: "so i can monitor" },
  { label: "so i can use", value: "so i can use" },
  { label: "so i can know", value: "so i can know" },
  { label: "so i can update", value: "so i can update" },
  { label: "so i can get", value: "so i can get" },
  { label: "so i can search", value: "so i can search" },
  { label: "so i can increase", value: "so i can increase" },
  { label: "so i can remove", value: "so i can remove" },
  { label: "so i can change", value: "so i can change" },
  { label: "so i can provide", value: "so i can provide" },
  { label: "so i can send", value: "so i can send" },
];

const additionalTexts = [
  { label: "transactions", value: "transactions" },
  { label: "finances", value: "finances" },
  { label: "hardware", value: "hardware" },
  { label: "authentication", value: "authentication" },
  { label: "security", value: "security" },
  { label: "organisation", value: "organisation" },
  { label: "statement", value: "statement" },
  { label: "checkout", value: "checkout" },
  { label: "money", value: "money" },
];

const ManualForm = ({ register, onSubmit, loading }) => {
  return (
    <div className="flex flex-col gap-5">
      <Select
        register={register}
        name="asA"
        label="As a"
        options={userActions}
        required
      />
      <Select
        register={register}
        name="iWantTo"
        label="I want to (optional)"
        options={iWants}
      />
      <Select
        register={register}
        name="contextA"
        label="Context (optional)"
        options={contexts}
      />
      <Select
        register={register}
        name="soICan"
        label="So I can (optional)"
        options={soIcans}
      />
      <Select
        register={register}
        name="additionalTextA"
        label="Additional Text (optional)"
        options={additionalTexts}
      />

      <TextArea
        register={register}
        name="acceptance_criteria"
        label="Acceptance Criteria"
      />
      <Button
        className="w-48 text-white mt-10 normal-case rounded-xl"
        onClick={onSubmit}
      >
        {loading ? <Spinner /> : "Add user story"}
      </Button>
    </div>
  );
};

export default ManualForm;
