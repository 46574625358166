import { ProjectCard } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllProjects } from "../features/projects/projectSlice";
import BreadCrumbs2 from "../components/ui/BreadCrumbs2";

const Projects = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.project);

  const projectMapper = projects.map((el) => ({
    uuid: el?.uuid,
    name: el?.projectTitle ?? "N/A",
    description: el?.projectDescription ?? "N/A",
    noOfActiveTasks: 4,
    date: "7th, Oct 2022",
  }));

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  console.log({ projects });
  return (
    <>
    <BreadCrumbs2/>
      <div className="flex justify-center sm:justify-between mb-9">
        <span className="text-background-2 text-xl">All projects</span>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
        {projectMapper.map((project, index) => (
          <ProjectCard key={index + 1} project={project} />
        ))}
      </div>
    </>
  );
};

export default Projects;
