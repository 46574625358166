/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Modal,
  SelectField,
  // Card
} from "../components/ui";
// import { DropWrapper } from "../components";
import { EmptyStoryState, Stories } from "../components/story";
// import { Tasks } from "../constants/data";

// import eclipseIcon from "../assets/icons/eclipse-icon.svg";
// import avatar from "../assets/images/avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserStoryWithAI,
  createUserStory,
} from "../features/user-story/userStory";
import { getAProject } from "../features/projects/projectSlice";
import { userStorySchema } from "../validations/schema";
import GenerateUserStory from "../components/story/GenerateUserStory";
import Breadcrumbs from "../components/ui/Breadcrumbs";

// const STATUSLIST = ["To Do", "Doing", "Done"];

const OPTIONS = [
  { label: "Sprint 1", value: "sprint1" },
  { label: "Sprint 2", value: "sprint2" },
  { label: "Sprint 3", value: "sprint3" },
];

// const ListWrapper = ({ status }) => {
//   return (
//     <div className="text-center mb-6">
//       <span className="text-grey-light">{status}</span>
//       <div className="bg-[#E5DDF0] w-72 h-5"></div>
//     </div>
//   );
// };

// const Item = ({ item, moveItem, setDragElement }) => {
//   const onDragStart = ({ dataTransfer, target }) => {
//     dataTransfer.setData("item", JSON.stringify(item));
//     setDragElement(item);
//     setTimeout(() => {
//       target.style.visibility = "hidden";
//     }, 1);
//   };

//   const onDragOver = (e) => {
//     moveItem(e.target.innerText);
//     e.preventDefault();
//   };
//   const onDragEnd = (e) => (e.target.style.visibility = "visible");
//   return (
//     <Card
//       className="text-sm mb-2 py-3 px-3 rounded-none cursor-pointer shadow-md"
//       draggable="true"
//       onDragStart={onDragStart}
//       onDragOver={onDragOver}
//       onDragEnd={onDragEnd}
//     >
//       <div className="flex items-center justify-between mb-1">
//         <span className="text-xs">{item.status}</span>
//         <img className="rotate-90" src={eclipseIcon} alt="" />
//       </div>
//       <p className="text-grey-subtle text-xs mb-[6px]">{item.story}</p>
//       <div className="flex items-center justify-between">
//         <div className="w-10 bg-[#E9DAFF] rounded text-center">
//           <span className="text-primary text-xs">{item.storyId}</span>
//         </div>
//         <img className="w-5" src={avatar} alt="" />
//       </div>
//     </Card>
//   );
// };

const UserStories = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(userStorySchema), mode: "all" });

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [sprintModalOpen, setSprintModalOpen] = useState(false);
  // const [items, setItems] = useState(Tasks);
  const [setDragEl] = useState();

  const dispatch = useDispatch();
  // const { state } = useLocation();
  const { project } = useSelector((state) => state.project);

  const [stories, setStories] = useState(project?.userStories);

  // const onDrop = (story, status) => {
  //   const filteredStories = stories.filter((el) => el?.uuid !== story?.uuid);

  //   setStories(filteredStories);
  //   // if (story.status === status) {
  //   //   return;
  //   // }

  //   // if (!item.status) {
  //   //   setSprintModalOpen(true);
  //   //   setStories((prevState) =>
  //   //     prevState.filter((story) => story.id !== item.id)
  //   //   );
  //   // }

  //   setItems((prevState) => {
  //     const newItems = prevState
  //       .filter((i) => i.id !== story.id)
  //       .concat({ ...story, status });
  //     // setStories(newItems);
  //     return newItems;
  //   });
  // };
  // console.log({ stories, project });
  // const moveItem = (el) => {
  //   setItems((prevState) => {
  //     /*  index of the iten that is being dragged */
  //     const itemIndex = prevState.findIndex(
  //       (i) => i.content === dragEl.content
  //     );
  //     /* index of the item being dragged over */
  //     const hoverIndex = prevState.findIndex((i) => i.content === el);

  //     /* make a copy of the old state */
  //     const newState = [...prevState];
  //     /* in case you're dragging the item from the user story for the first time */
  //     if (itemIndex < 0) {
  //       newState.splice(hoverIndex, 0);
  //     } else {
  //       newState.splice(itemIndex, 1);
  //     }
  //     newState.splice(hoverIndex, 0, dragEl);
  //     return [...newState];
  //   });
  // };

  const setDragElement = (el) => setDragEl(el);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSprint = () => setSprintModalOpen(false);

  const onSubmit = handleSubmit((body) => {
    setIsLoading(true);
    dispatch(createUserStory({ projectId: project?.uuid, body })).then(
      ({ payload }) => {
        if (payload?.success) {
          setIsLoading(false);
          setIsOpen(false);
          reset();
          return dispatch(getAProject(project?.uuid)).then((res) => {
            setStories(res?.payload?.data?.userStories);
          });
        }
        setIsOpen(false);
        setIsLoading(false);
      }
    );
  });

  const generateWithAi = () => {
    setIsLoading(true);
    dispatch(createUserStoryWithAI(project?.uuid)).then(({ payload }) => {
      if (payload?.success) {
        setIsLoading(false);
        setIsOpen(false);
        return dispatch(getAProject(project?.uuid)).then((res) => {
          setStories(res?.payload?.data?.userStories);
        });
      }
      setIsOpen(false);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getAProject(project?.uuid));
  }, [dispatch]);
  // console.log({ state });

  return (
    <div className="mt-6">
      <Breadcrumbs />
      {/* FIRST MODAL */}
      <Modal className="w-[549px]" isOpen={isOpen} handleClose={handleClose}>
        <GenerateUserStory
          register={register}
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          generateWithAi={generateWithAi}
        />
      </Modal>

      <div className="flex items-center justify-between">
        <span className="font-bold text-2xl text-background-2">
          {project?.projectTitle ?? "User Story"}
        </span>
        <Button className="normal-case" onClick={handleOpen}>
          Create user story
        </Button>
      </div>
      <div className="max-w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:lg:grid-cols-4 gap-2 mt-10">
        {stories?.length > 0 ? (
          <Stories stories={stories} setDragElement={setDragElement} />
        ) : (
          <EmptyStoryState />
        )}
      </div>
      {/* <div>
        {items.length > 0 ? (
          <div className="flex justify-between mt-[71px]">
            {STATUSLIST.map((status) => (
              <div
                key={status}
                className="flex flex-col m-5 p-5 rounded bg-[#F1F3F5]"
              >
                <ListWrapper key={status} status={status} />
                <DropWrapper onDrop={onDrop} status={status}>
                  <div className="w-[200px] mx-auto">
                    Holla
                    {items
                      .filter((i) => i.status === status)
                      .map((i, index) => (
                        <Item
                          key={index}
                          item={i}
                          moveItem={moveItem}
                          setDragElement={setDragElement}
                        />
                      ))}
                  </div>
                </DropWrapper>
              </div>
            ))}
          </div>
        ) : (
          <span className="flex items-center justify-center mt-48">
            Drag user stories here to track your progress
          </span>
        )}
      </div> */}

      <Modal
        isOpen={sprintModalOpen}
        position="center"
        handleClose={() => setSprintModalOpen(false)}
      >
        <div className="my-5">
          <SelectField
            label="Choose sprint to add user story"
            options={OPTIONS}
          />
          <Button
            color="bg-primary"
            className="text-white mt-10"
            onClick={handleSprint}
          >
            Add user story
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UserStories;
