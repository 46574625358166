import React from "react";
import { Button } from "../components/ui";
import progress from "../assets/icons/progress.svg";
import { Link } from "react-router-dom";
import { userWalletPath } from "../constants/paths";
import vertical from "../assets/icons/vertical.svg";
import CircleImage from "../components/ui/CircleImage";

const AllSprint = () => {
  const tabs = [
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
    {
      title: "sprint 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet lectus sapien, nam at tincidunt nunc elementum lectus leo.",
      task: "4",
      date: "7th, Oct 2022",
      progress: "",
    },
  ];

  return (
    <div className="mx-[-32px]">
      {" "}
      <div className="font-sans text-[20px] font-medium my-8">
        All My Sprint
      </div>
      <div className="flex flex-wrap gap-5">
        {tabs.map((props) => {
          return (
            <div
              key={props.id}
              className="w-[360px] h-[284px] bg-[#FFFFFF] px-6 py-7 "
            >
              <div className="flex justify-between mb-4">
                <h3 className="text-[20px] font-medium font-sans">
                  {props.title}
                </h3>
                <img src={vertical} alt="no img" />
              </div>
              <div className="font-sans text-sm mb-4">{props.content}</div>
              <div className="flex items-center">
                <div className="text-[#6335A6] font-sans text-sm mr-6 mb-1">
                  {props.task} Task Active
                </div>
                <CircleImage />
              </div>

              <div className="flex justify-between text-[12px] mb-[-15px] items-center">
                <p>Created{props.date}</p>
                <Link to={userWalletPath}>
                  <Button
                    color="#F7F3FB"
                    className="bg-[#F7F3FB] text-purple-800"
                  >
                    View Sprint
                  </Button>
                </Link>
              </div>
              <div className="mt-[3rem]">
                <img src={progress} alt="no img" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllSprint;
