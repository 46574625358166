import React, { useState } from "react";
import {
  Button,
  FileUpload,
  Input,
  Modal,
  SelectField,
  Spinner,
  TextArea,
} from "../components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreatePostSchema } from "../validations/schema";
import adobe from "../assets/icons/adobe.svg";
import meet from "../assets/icons/meet.svg";
import skype from "../assets/icons/skype.svg";
import udemy from "../assets/icons/udemy.svg";
import photoshop from "../assets/icons/photoshop.svg";
import trash from "../assets/icons/trash.svg";
import vw from "../assets/icons/vw.svg";

import { useEffect } from "react";
import ViewPost from "../components/ViewPost";

const CreatePost = () => {
  const [create, setCreate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading] = useState(false);
  const {
    register,

    formState: { errors },
  } = useForm({ resolver: yupResolver(CreatePostSchema), mode: "all" });

  const closeCreate = () => {
    setCreate(!create);
  };

  useEffect(() => {
    if (open === true) return setCreate(false);
  }, [open, setCreate]);

  const PostOpen = () => {
    setOpen(!open);
  };
  const options = [
    { value: 0, label: "Productivity" },
    { value: 1, label: "Mobile" },
  ];
  return (
    <div className="mb-6">
      <h1 className="text-[24px] text-background-2">Product Review</h1>
      <div className="flex flex-col gap-5 mt-8">
        <Input
          errors={errors}
          register={register}
          name="productTitle"
          type="text"
          id="productTitle"
          label="Project Title"
          placeholder="Give your project a name."
          required
        />
        <SelectField
          register={register}
          name="companySector"
          id="companySector"
          label="Comapany Sector"
          options={options}
          required
        />
        <SelectField
          register={register}
          name="productType"
          id="productType"
          label="Product Type"
          options={options}
          required
        />
        <TextArea
          errors={errors}
          register={register}
          name="productDescription"
          label="Product Description"
          placeholder="A short description of your project"
        />
        <FileUpload
          label="Upload image shots"
          subText="Image should be at least 1600x1200 or higher recommended. Max 10MB each (20MB for GIF)"
        />
      </div>
      <div className="flex justify-center flex-wrap gap-2">
        <img src={adobe} className=" cursor-pointer" alt="no img" />
        <img src={meet} className="ml-4 cursor-pointer" alt="no img" />
        <img src={skype} className="ml-4 cursor-pointer" alt="no img" />
        <img src={udemy} className="lg:ml-4 cursor-pointer" alt="no img" />
        <img src={photoshop} className="ml-4 cursor-pointer" alt="no img" />
        <img src={trash} className="ml-10 cursor-pointer" alt="" />
        <div className="w-8">
          
        </div>
      </div>
      <FileUpload
        label="Upload Company Logo *"
        subText="Image should be at least 500 pixels wide and only *.jpg,* *.png* and *.jpeg* formats are accepted"
      />
      <div className="mt-2 flex">
        <img src={vw} className="cursor-pointer" alt="" />
        <img src={trash} className="ml-10 cursor-pointer" alt="" />
      </div>
      <Button className="mt-6" onClick={closeCreate}>
        {loading ? <Spinner /> : "Submit Post"}
      </Button>

      {/* 
    Modal to successful creation of post */}
      <Modal
        isOpen={create}
        position="top"
        className="w-[80%] lg:w-[529px]"
        handleClose={closeCreate}
      >
        <div className=" my-[48px] flex flex-col justify-center items-center">
          <div className="text-[20px] text-center font-medium font-sans w-[350px]">
            Congratulations, your post will be availabe for review soon.
          </div>
          <div className="text-[14px] text-center font-normal font-sans w-[415px]">
            Lorem ipsum dolor sit amet consectetur. Urna velit et dignissim
            pharetra. Lobortis ac sagittis dolor arcu sed viverra.
          </div>
          <Button
            onClick={PostOpen}
            color="bg-primary"
            className="text-white w-[240px] mt-10"
          >
            View Post
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={open}
        position="top"
        className="lg:w-[1152px] w-full h-auto"
        handleClose={PostOpen}
      >
        <ViewPost />
      </Modal>
    </div>
  );
};

export default CreatePost;
