import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Logo, ErrorMessage } from "../../components";
import { Cans, Google, LinkedIn, Twitter } from "../../components/Google";
import Slider from "../../components/Slider";
import {
  ForgotPasswordPath,
  UserDashboardPath,
  RegisterPath,
} from "../../constants/paths";

import { login } from "../../features/auth/authSlice";
import { loginSchema } from "../../validations/schema";
import { useState } from "react";
import { EyeTwoTone } from "@ant-design/icons";
import { EyeInvisibleOutlined } from "@ant-design/icons/lib/icons";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema), mode: "all" });

  const { loading, loginError } = useSelector((state) => state.auth);

  const [type, setType] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (data) => {
    const response = dispatch(login(data));
    if (response.payload?.success) {
      navigate(UserDashboardPath);
    }
  };
  return (
    <div className="flex justify-between h-full">
      <div className="flex-[1.1] xl:w-[60%] xl:absolute">
        <Logo className="pl-[16px] mb-8 lg:pl-[7.5rem] pt-[3rem]" />
        <div className="flex flex-col justify-center items-center lg:ml-20">
          <div className="text-transparent font-libre bg-clip-text bg-gradient-to-r from-color1 to-color2 font-bold text-4xl pb-5">
            Log In
          </div>
          <div className="flex mb-8">
            <span className="">
              {" "}
              <Google />
            </span>
            <span className="pl-5">
              <Twitter />
            </span>
            <span className="pl-5">
              <LinkedIn />
            </span>
          </div>
          <div
            className='mb-1 relative 
          before:content-[""] before:block before:w-[45px] lg:before:w-[140px] before:h-[1px] before:bg-black before:absolute before:right-[12rem] before:top-[11px]
          after:content-[""] after:block after:w-[45px] lg:after:w-[140px] after:h-[1px] after:bg-black after:absolute after:left-[12rem] after:top-[11px]'
          >
            <p className="text-[16px]">Or login with your email</p>
          </div>
          {loginError && (
            <div className="w-[340px] sm:w-[450px] h-[40px] lg:w-[500px] lg:h-[50px] mt-5 rounded-2xl px-5 py-3 bg-[#FFE4E4] text-[#FF3B3B] text-lg flex justify-between items-center">
              <div>Invalid Credential</div> <Cans />
            </div>
          )}
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">
                Email Address
              </label>
              <input
                type="email"
                className="w-[340px] sm:w-[450px] h-[50px] lg:w-[500px] lg:h-[60px] focus:outline-none focus:border-primary pl-6 text-sm border-solid border-[0.5px] border-[#87838C] rounded-2xl"
                placeholder="you@example.com"
                {...register("email")}
              />
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            </div>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">Password</label>
              <input
                className="w-[340px] sm:w-[450px] h-[50px] lg:w-[500px] lg:h-[60px] focus:outline-none focus:border-primary pl-6 text-sm border-solid border-[1px] border-[#87838C] rounded-2xl"
                type={type ? "text" : "password"}
                placeholder="password"
                {...register("password")}
              />
              <span
                className="mt-[-2.3rem] w-[10px] ml-[28rem] justify-self-end mb-3 px-6 cursor-pointer flex justify-end"
                onClick={(e) => {
                  e.preventDefault();
                  type === false ? setType(true) : setType(false);
                }}
              >
                {type ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
              </span>
              <ErrorMessage>{errors.password?.message}</ErrorMessage>

              <Link to={ForgotPasswordPath}>
                <div className=" mt-2">
                  <p className="text-primary text-end text-sm font-bold">
                    Forgot Password?
                  </p>
                </div>
              </Link>
            </div>
            {/* <div className="btn-secondary-large"> */}
            <button className="btn-secondary-large !text-center">
              {loading ? "Loading..." : "Log In"}
            </button>
            {/* </div> */}
          </form>

          <div className="mt-5 flex">
            <span>
              <h5>Dont have an account?</h5>
            </span>
            <Link to={RegisterPath}>
              <span>
                <p className="text-primary">Create Account</p>
              </span>
            </Link>
          </div>
          <div className="w-[300px] lg:w-[500px] text-[10px] mt-10 mb-[25px]">
            By creating an account, you are agreeing to our{" "}
            <span className="text-[#FF8080]">Terms of Service</span> and{" "}
            <span className="text-[#FF8080]">Privacy Policy</span> . You also
            agree to receive product-related marketing emails from Requstory,
            which you can unsubscribe from at any time.
          </div>
        </div>
      </div>

      <Slider />
    </div>
  );
};

export default Login;
