import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { Logo, ErrorMessage } from "../../components";
import { Google, LinkedIn, Twitter } from "../../components/Google";
import Slider from "../../components/Slider";
import { LoginPath, UserDashboardPath } from "../../constants/paths";

import { signUp } from "../../features/auth/authSlice";
import { registerSchema } from "../../validations/schema";

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema), mode: "all" });
  const { loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (data) => {
    const response = dispatch(signUp(data));
    if (response.payload?.data.success === true) {
      navigate(UserDashboardPath);
    }
  };
  return (
    <div className="flex justify-between h-full ">
      <div className="flex-[1.1] xl:w-[60%] xl:absolute">
        <Logo className="pl-[16px] mb-8 lg:pl-[7.5rem] pt-[3rem]" />
        <div className="flex flex-col justify-center items-center lg:ml-20">
          <div className="text-transparent font-libre bg-clip-text bg-gradient-to-r from-color1 to-color2  font-bold text-4xl pb-5">
            Create Account
          </div>
          <div className="flex mb-8">
            <span className="">
              {" "}
              <Google />
            </span>
            <span className="pl-5">
              <Twitter />
            </span>
            <span className="pl-5">
              <LinkedIn />
            </span>
          </div>
          <div
            className='mb-1 relative 
          before:content-[""] before:block before:w-[45px] lg:before:w-[130px] before:h-[1px] before:bg-black before:absolute before:right-[12rem] before:top-[11px]
          after:content-[""] after:block after:w-[45px] lg:after:w-[130px] after:h-[1px] after:bg-black after:absolute after:left-[12rem] after:top-[11px]'
          >
            <p className="text-[16px]">Or register with your email</p>
          </div>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">Email</label>
              <input
                type="text"
                className="w-[340px] sm:w-[450px] h-[50px] lg:w-[500px] lg:h-[60px] pl-6 text-sm border-solid border-[0.5px] focus:outline-none focus:border-primary border-[#87838C] rounded-2xl"
                placeholder="you@example.com"
                {...register("email")}
              />
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            </div>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">Password</label>
              <input
                className="w-[340px] sm:w-[450px] h-[50px] lg:w-[500px] lg:h-[60px] pl-6 text-sm border-solid border-[0.5px] focus:outline-none focus:border-primary border-[#87838C] rounded-2xl"
                type="password"
                placeholder="password"
                {...register("password")}
              />
              <ErrorMessage>{errors.password?.message}</ErrorMessage>
            </div>
            <div className="btn-secondary-large">
              <button type="submit">
                {loading ? "Loading..." : "Create Account"}
              </button>
            </div>
          </form>

          <div className="mt-5 flex">
            <span>
              <h5>Already have an account?</h5>
            </span>
            <Link to={LoginPath}>
              <span>
                <p className="text-primary">Log in</p>
              </span>
            </Link>
          </div>
          <div className="w-[300px] lg:w-[500px] text-center text-[10px] mt-10 mb-5">
            By creating an account, you are agreeing to our{" "}
            <span className="text-[#FF8080]">Terms of Service</span> and{" "}
            <span className="text-[#FF8080]">Privacy Policy</span> . You also
            agree to receive product-related marketing emails from Requstory,
            which you can unsubscribe from at any time.
          </div>
        </div>
      </div>
      <Slider />
    </div>
  );
};

export default Register;
