import Label from "./Label";

const SelectField = ({ label, id, required, options, register, name }) => {
  const registerInput = register ? { ...register(name ?? "") } : null;
  return (
    <div>
      <Label id={id} required={required}>
        {label}
      </Label>
      <select
        className="w-full py-4 px-5 text-sm border border-grey-light text-grey-light rounded focus:ring-primary-lighter focus:ring-1 focus:outline-none appearance-none select-icon"
        id={id}
        {...registerInput}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
