import React from "react";
import { Logo } from "../../components";
import Slider from "../../components/Slider";
import { Link } from "react-router-dom";
import { LoginPath } from "../../constants/paths";
import { ArrowLe, Mail } from "../../components/Google";

const CheckEmail = () => {
  
  return (
    <div className="flex justify-between h-full ">
      <div className="flex-[1.1] xl:w-[60%] xl:absolute">
      <Logo className="pl-[16px] mb-8 lg:pl-[7.5rem] pt-[3rem]" />
        <div className="flex flex-col justify-center items-center lg:ml-20">
          <div className="mt-7">
            <Mail />
          </div>
          <div className="text-transparent font-libre bg-clip-text bg-gradient-to-r from-color1 to-color2 font-bold text-4xl pb-5">
            Check your mail
          </div>
          <p className="font-medium text-[14px]">
            We sent a password reset link to
          </p>
          <p className="font-medium text-[14px]">hello@requstory.com</p>

          <div className="btn-secondary-large" >
            <button>Open Email</button>
          </div>
          <div className="mt-5 flex text-[14px]">
            <span>
              <h5>Didn’t receive the email?</h5>
            </span>
            <span>
              <p className="text-primary">Click to resend</p>
            </span>
          </div>
          <Link to={LoginPath}>
          <div className="mt-5 flex">
          <span className="pt-1"><ArrowLe/></span>
            <span>
              <h5>Back to login</h5>
            </span>
          </div>
          </Link>
        </div>
      </div>
       
        <Slider />
       
    </div>
  );
};

export default CheckEmail;
