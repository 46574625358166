import React from "react";
import paid from "../../assets/icons/paid.svg";
import cloud from "../../assets/icons/cloud.svg";

const BIllings = () => {
  const list = [{}, {}, {}, {}];
  return (
    <>
      <div className="lg:w-[920px] sm:w-[50%] bg-white py-6 pl-6 mb-4 mx-auto mt-8">
        <h4 className="text-[20px] font-meduim font-sans">Billing</h4>
        <h5 className="text-[16px] font-normal text-[#87838C] font-sans">
          Manage billng and payment informations
        </h5>
        <p className="text-[16px] mt-[32px] mb-[12px] font-[450]">
          Busness Plan
        </p>
        <h4 className="text-[16px]  text-[#6335A6] mb-3 font-bold">
          $50 Month
        </h4>
        <h6 className="text-[14px] text-[#87838C] font-normal">
          {" "}
          Up to 10 users, access to all features, best for high growth
          enterprise
        </h6>
      </div>
      <div className="flex max-w-full overflow-x-auto">
        <div className="lg:w-[920px] hidden lg:grid max-w-full overflow-x-auto bg-white py-6 px-6 mx-auto">
          <p className="text-[24px] font-bold font-sans">Order History</p>
          <div className="w-[856px] h-[0.5px] bg-[#CFCED1]"></div>
          <div className="flex my-6">
            <span className="w-[30%] font-normal font-sans text-[16px]">
              Plan
            </span>
            <span className="flex w-[70%]  font-normal font-sans text-[16px]">
              <p className="flex-[1] flex justify-center">Date</p>
              <p className="flex-[1] flex justify-center">Amount</p>
              <p className="flex-[1] flex justify-center">Status</p>
              <p className="flex-[1] flex justify-center">Download</p>
            </span>
          </div>
          {list.map((item) => {
            return (
              <div className="flex mb-6" key={item.id}>
                <span className="w-[30%] font-normal font-sans text-[16px]">
                  Enterprise
                </span>
                <span className="flex w-[70%] font-normal font-sans text-[16px]">
                  <p className="flex-[1] flex justify-center">Oct 11, 2020</p>
                  <p className="flex-[1] flex justify-center">USD $10</p>
                  <p className="flex-[1] flex justify-center">
                    {" "}
                    <div className="h-[30px] w-[71px]">
                      <img
                        className="w-full h-full"
                        src={paid}
                        alt="profile picure"
                      />
                    </div>
                  </p>
                  <p className="flex-[1] flex justify-center">
                    {" "}
                    <div className="h-[18px] w-[18px]">
                      <img
                        className="w-full h-full"
                        src={cloud}
                        alt="profile picure"
                      />
                    </div>
                  </p>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BIllings;
