import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  FileUpload,
  Input,
  Modal,
  TextArea,
} from "../components/ui";
import { HeadingText, Paragraph, IconContainer } from "../components/dashboard";
import {
  UserStoriesPath,
  UserSprintPath,
  UserCreateProjectPath,
} from "../constants/paths";
import edit from "../assets/icons/edit.svg";
import robot from "../assets/icons/robot.svg";
import planning from "../assets/icons/planning.svg";
import process from "../assets/icons/process.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import DateInput from "../components/ui/DateInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { MdArrowBackIosNew } from "react-icons/md";

const TODOS = [
  {
    icon: robot,
    heading: "Write User Stories",
    paragraph: "Let the AI do the writing for you",
    path: UserStoriesPath,
    iconClass: "bg-success-lighter",
  },
  {
    icon: planning,
    heading: "Sprint Planning",
    paragraph: "Automated sprint planning",
    path: UserSprintPath,
    iconClass: "bg-info-lighter",
  },
  {
    icon: process,
    heading: "Draw Process Map",
    paragraph: "Automatically draw process maps",
    path: UserStoriesPath,
    iconClass: "bg-secondary",
  },
];

const NewProject = () => {
  const [create, setCreate] = useState(false);
  const { project } = useSelector((state) => state.project);
  const handleClose = () => {
    setCreate(false);
  };

  const {
    control,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(), mode: "all" });

  const navigate = useNavigate();
  return (
    <>
      <div className="mt-4 ">
        <div
          className="py-3 flex items-center mb-3 cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdArrowBackIosNew className="font-bold h-5 w-5 mr-2" /> Back
        </div>

        <div className="flex justify-between mb-6">
          <div className="flex items-center gap-4">
            <div className="flex gap-2">
              <h1 className="font-bold text-[32px] text-background-2">
                {project.projectTitle}
              </h1>
              <span className="flex">
                {" "}
                <img
                  src={edit}
                  onClick={() => setCreate(true)}
                  alt="no img"
                  className="cursor-pointer"
                />
              </span>
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                return navigate(UserCreateProjectPath);
              }}
              className="normal-case"
            >
              Create new project
            </Button>
          </div>
        </div>
        <span className="text-xl text-[#666666]">What do you want to do?</span>
        <div className="py-6 flex items-start gap-3 lg:gap-[22px] mt-6 mb-14 pr-6 max-w-full overflow-x-auto lg:overflow-x-visible">
          {TODOS.map((todo, index) => (
            <div
              key={index}
              className="shrink-0 basis-[180px] lg:basis-[33.3%]"
            >
              <Card className="flex flex-col justify-center items-center text-heading h-[240px] shadow-large rounded-lg">
                <IconContainer className={todo.iconClass}>
                  <img src={todo.icon} alt="Requstory features icon" />
                </IconContainer>
                <HeadingText>{todo.heading}</HeadingText>
                <Paragraph>{todo.paragraph}</Paragraph>
                <div className="min-w-[190px] mt-6">
                  <Button
                    onClick={() => navigate(todo.path)}
                    fullWidth
                    variant="outlined"
                  >
                    Start
                  </Button>
                </div>
              </Card>
            </div>
          ))}
        </div>

        {/* Modal to edit */}

        <Modal
          isOpen={create}
          position="Top"
          className="w-[534px]"
          handleClose={handleClose}
        >
          <h1 className="font-sans mb-8 text-2xl">Edit Project</h1>
          <Input label="Project Title" placeholder="Fintech App" />
          <TextArea label="Project Description" />
          <FileUpload label="Project Logo" />
          <div className="flex mt-4">
            <span className="w-[45%] mr-4">
              {" "}
              <DateInput
                control={control}
                errors={errors}
                register={register}
                name="date"
                type="date"
                id="title"
                label="Start Date"
                placeholder="dd/mm/yy."
              />
            </span>
            <span className="w-[45%]">
              {" "}
              <DateInput
                control={control}
                errors={errors}
                register={register}
                name="date"
                type="date"
                id="title"
                label="Start Date"
                placeholder="dd/mm/yy."
              />
            </span>
          </div>
          <Button className="normal-case mt-5">Create</Button>
        </Modal>
      </div>
    </>
  );
};

export default NewProject;
