import { useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import MobileSidebar from './Sidebar/MobileSidebar';
import Navbar from './Navbar';
import { SearchInput } from '../components/ui';
import { useSelector } from 'react-redux';

const UserLayout = ({ children }) => {
  const {sidenav} = useSelector((state)=>state.action)
  const [showSideDrawer, setSideDrawer] = useState(false);

  const sideDrawerCloseHandler = () => {
    setSideDrawer(false);
  };
  const sideDrawerToggleHandler = () => {
    setSideDrawer(!showSideDrawer);
  };

  return (
    <div className="grid  md:grid-cols-12 min-h-screen h-full bg-[#F3F4FD] px-6 md:px-0">
      <div className={`${sidenav?'xl:hidden -translate-x-full duration-500':''} duration-500 hidden md:inline-block md:col-span-3 xl:col-span-2`}>
        <Sidebar />
      </div>

      <MobileSidebar open={showSideDrawer} closed={sideDrawerCloseHandler} />
      <div className={`${sidenav?'w-[100vw]':''} md:col-span-9 xl:col-span-10 h-full`}>
        <Navbar openSideDrawer={sideDrawerToggleHandler} />
        <div className="md:hidden">
          <SearchInput color="bg-white" type="text" placeholder="Search" />
        </div>
        <main className={`${sidenav?'w-[100vw] duration-500':''} duration-500 pt-10 md:px-16 md:pt-24`}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default UserLayout;
