import DatePicker from "react-date-picker";
import Label from "./Label";
import moment from "moment/moment";

const DateInput = ({
  name = "",
  type,
  id,
  label,
  placeholder,
  className,
  required,
  register,
  control,
  errors = {},
}) => {
  const registerInput = register ? { ...register(name ?? "") } : null;
  return (
    <div>
      <Label id={id} required={required}>
        {label}
      </Label>
      <input
        id={id}
        control={control}
        type={type}
        className={`h-14 w-full px-5 rounded z-0 text-sm border ${className} ${
          errors[name]
            ? "!border-red-500"
            : "border-grey-light focus:ring-primary-lighter"
        }
          
          
            text-grey-light  focus:ring-1 focus:outline-none ${className}`}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            placeholderText="Select date"
            onChange={(date) => onChange(date)}
            selected={value ? moment(value).toDate() : new Date()}
          />
        )}
        placeholder={placeholder}
        {...registerInput}
      />
    </div>
  );
};

export default DateInput;
