import React, { useState } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import Label from "./Label";

function PasswordInput({
  name = "",
  type,
  id,
  label,
  placeholder,
  className,
  required,
  register,
  errors = {},
}) {
  const [showPassword, setShowPassword] = useState(false);
  const registerInput = register ? { ...register(name ?? "") } : null;
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      <Label id={id} required={required}>
        {label}
      </Label>
      <input
        type={showPassword ? "text" : "password"}
        className={`h-14 w-full px-5 rounded z-0 text-sm border ${className} ${
          errors[name]
            ? "!border-red-500"
            : "border-grey-light focus:ring-primary-lighter"
        }
          
          
            text-grey-light  focus:ring-1 focus:outline-none ${className}`}
        placeholder={placeholder}
        {...registerInput}
      />
      <button
        type="button"
        className="absolute top-16 right-6 transform -translate-y-1/2 focus:outline-none"
        onClick={handleTogglePassword}
      >
        {showPassword ? <HiEyeOff /> : <HiEye />}
      </button>
    </div>
  );
}

export default PasswordInput;
