import React from "react";
import { Logo } from "../../components";
import Slider from "../../components/Slider";
import { Check } from "../../components/Google";

const ResetSuccessful = () => {

  return (
    <div className="flex justify-between h-full ">
      <div className="flex-[1.1] xl:w-[60%] xl:absolute">
        <Logo className="pl-[16px] mb-8 lg:pl-[7.5rem] pt-[3rem]" />
        <div className="flex flex-col justify-center items-center lg:ml-20">
          <div className="mt-7">
            <Check />
          </div>
          <div className="text-primary-lighter font-bold text-4xl pb-5">
            Check your mail
          </div>
          <p className="font-medium text-[14px]">
            Your password has been successfully reset
          </p>
          <p className="font-medium text-[14px]">Click below to log in</p>

          <div className="btn-secondary-large">
            <button>Open Email</button>
          </div>
          <div className="mt-5 flex text-[14px]">
            <span>
              <h5>Didn’t receive the email?</h5>
            </span>
            <span>
              <p className="text-primary">Click to resend</p>
            </span>
          </div>
        </div>
      </div>

      <Slider />
    </div>
  );
};

export default ResetSuccessful;
