import { FiSearch } from 'react-icons/fi';

const SearchInput = ({
  type,
  placeholder,
  onSubmit,
  color = 'bg-primary-light-4',
}) => {
  return (
    <form onSubmit={onSubmit} className="relative w-full">
      <div className="absolute top-2 lg:top-4 left-2 lg:left-6 pointer-events-none">
        <FiSearch size={24} color="#87838C" />
      </div>
      <input
        type={type}
        className={`h-10 lg:h-14 w-full pl-10 lg:pl-16 pr-10 lg:pr-20  rounded-lg z-0 border-none   focus:ring-primary-lighter focus:ring-1 focus:outline-none placeholder:text-grey-light placeholder:font-light lg:placeholder:text-lg 4 ${color}`}
        placeholder={placeholder}
      />
    </form>
  );
};

export default SearchInput;
