import React from "react";
import { Button, Input } from "../components/ui";
import avatar from "../assets/images/avatar.svg";
import BreadCrumbs2 from "../components/ui/BreadCrumbs2";
const WorkSpace = () => {
  return (
    <div>
      <BreadCrumbs2/>
      <>
        <div className="w-full lg:w-[920px] mx-auto mt-6 pb-8 mb-7 lg:py-6 bg-white pl-3 lg:pl-6">
          <p className="text-[24px] pt-3 font-normal font-sans">
            Workspace Setting
          </p>
          <div className="w-[95%] lg:w-[856px] mb-6 h-[0.5px] bg-[#CFCED1]"></div>

          <div className="flex flex-col lg:flex-wrap gap-4">
            <div className="w-[95%] lg:w-[75%]">
              <Input
                label="Workspace Name"
                type="text"
                placeholder="Fintech Product"
              />
            </div>
            <div className="w-[95%] lg:w-[75%]">
              <Input
                label="Timezone"
                type="text"
                placeholder="(WAT-06:00) Lagos"
              />
            </div>
            <div className="lg:flex">
              <div className="w-[95%] lg:w-[55%]">
                <Input
                  label="Work Space Members (1/1)"
                  type="text"
                  placeholder="enter your name"
                />
              </div>
              <div className="flex lg:ml-5 my-4 lg:mt-[3.2rem] w-[68px] border-1 h-[35px]">
                <select className="">
                  <option value="Admin">Admin</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div className="lg:pt-[2.5rem] lg:ml-5 items-end">
                <Button color="red" className="bg-primary text-white">
                  Invite
                </Button>
              </div>
            </div>

            <div className="w-[95%] lg:w-[695px] mb-6 h-[2px] bg-[#CFCED1]"></div>
          </div>

          <div className="flex mt-7 w-[75%] justify-between">
            <div className="flex">
              <div className="h-[28px] mr-[14px] w-[28px] lg:h-[46px] lg:w-[46px]">
                <img
                  className="w-full h-full"
                  src={avatar}
                  alt="profile picure"
                />
              </div>
              <div>
                <p className="text-[14px] font-sans font-normal">
                  Email Address
                </p>
                <p className="text-[14px] mb-4 font-sans font-normal">
                  ekwochiadaeze@gmail.com
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-[35px] w-[75px] ml-5 lg:ml-0 flex items-center justify-center rounded-sm cursor-pointer border-2 ">
                Admin
              </div>
            </div>
          </div>
          <Button
            size="large"
            color="bg-primary"
            className="text-white mt-10 bg-gradient-to-r from-primary to-primary-light mr-7"
          >
            Update
          </Button>
        </div>
        <div className="w-full lg:w-[920px] mx-auto mt-8 pb-5 mb-7 py-6 bg-white pl-6">
          <p className="text-[24px] font-normal font-sans">Delete WorkSpace</p>
          <div className="w-[90%] lg:w-[856px] mb-6 h-[0.5px] bg-[#CFCED1]"></div>
          <div className="flex lg:w-[75%] text-base font-sans">
            Don’t feel like using Requstory anymore? Say the word and we will
            help you delete your account.
          </div>
          <div className="font-san text-base text-[#E53535] mt-12 cursor-pointer">
            Delete WorkSpace
          </div>
        </div>
      </>
    </div>
  );
};

export default WorkSpace;
