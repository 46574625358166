/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, Modal, TextArea } from "../ui";
import { UserAllSprintPath } from "../../constants/paths";
import arrowr from "../../assets/icons/arrowr.svg";
// import DropWrapper from "../DropWrapper";
import { Tabs } from "../Tabs";
import { useDispatch, useSelector } from "react-redux";

import { Stories as StoriesData } from "../../constants/data";
import { EmptyStoryState, Stories } from "../story";
import { getAProject } from "../../features/projects/projectSlice";
// import edit from "../../assets/icons/edit.svg";
import SprintBoard from "./SprintBoard";
import { updateUserStory } from "../../features/user-story/userStory";

const SprintState = ({ stories, setStories }) => {
  const [isCreate, setIsCreate] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const { project } = useSelector((state) => state.project);
  const [activeSprint, setActiveSprint] = useState(0);
  const [, setDraggedEl] = useState();

  const dispatch = useDispatch();

  const closeCreate = () => {
    setIsCreate(false);
  };
  // const setOpen= ()=>{
  //   setIsOpen(true)
  // }
  React.useEffect(() => {
    dispatch(getAProject(project.uuid));
    setStories(project.userStories);
  }, [dispatch, project.uuid]);
  const setDragElement = (el) => setDraggedEl(el);
  const onDrop = (story, progressId) => {
    // console.log("Ondrop", { story, progressId });
    const filteredStories = stories.filter((el) => el?.uuid !== story?.uuid);
    setStories(filteredStories);
    const body = {
      projectId: project.uuid,
      storyId: story.uuid,
      progressId,
    };
    dispatch(updateUserStory(body)).then((res) => {
      // console.log("RESPONSE", res);
      if (res.payload.success) {
        dispatch(getAProject(project.uuid));
      }
    });
  };
  // const moveItem = (el) => {
  //   setStories((prevState) => {
  //     /*  index of the iten that is being dragged */
  //     const itemIndex = prevState.findIndex(
  //       (i) => i.content === draggedEl.content
  //     );
  //     /* index of the item being dragged over */
  //     const hoverIndex = prevState.findIndex((i) => i.content === el);

  //     /* make a copy of the old state */
  //     const newState = [...prevState];
  //     /* in case you're dragging the item from the user story for the first time */
  //     if (itemIndex < 0) {
  //       newState.splice(hoverIndex, 0);
  //     } else {
  //       newState.splice(itemIndex, 1);
  //     }
  //     newState.splice(hoverIndex, 0, draggedEl);
  //     return [...newState];
  //   });
  // };
  // console.log({ draggedEl });
  return (
    <div className="overflow-hidden">
      <div className="flex max-w-full overflow-x-auto gap-2 mt-10">
        {StoriesData.length > 0 ? (
          <Stories stories={stories} setDragElement={setDragElement} />
        ) : (
          <EmptyStoryState />
        )}
      </div>
      <div className="flex justify-between mt-12 mb-4 w-full">
        <Tabs
          list={project}
          activeSprint={activeSprint}
          setActiveSprint={setActiveSprint}
        />
        <Link to={UserAllSprintPath}>
          <div className=" flex text-[14px] font-bold text-primary cursor-pointer">
            View all Sprint
            <img src={arrowr} alt="no img" className="ml-[1rem]  " />
          </div>
        </Link>
      </div>
      <div className="h-[0.2px] bg-slate-400 mt-[-18px] mb-4"></div>
      <div className="bg-white pl-4 font-thin mb-3 py-3 flex items-center">
        <span className="text-[14px] font-bold mr-2">Sprint Goal -</span> Lorem
        ipsum dolor sit amet, consectetur adipiscing elit.
        {/* <span className="flex">
          {" "}
          <img src={edit} alt="no img" className="cursor-pointer" />
        </span> */}
      </div>
      {/* Sprint Board Section */}
      <div className="flex max-w-full overflow-x-auto gap-2">
        <div className="flex max-w-full overflow-x-auto">
          {/* Sprint Board*/}
          {project?.projectSprints[activeSprint]?.sprintProgresses?.map(
            (e, i) => {
              return (
                <SprintBoard
                  key={i}
                  sprint={e}
                  onDrop={onDrop}
                  progressId={e?.progressuid}
                />
              );
            }
          )}
          {/* End of Sprint Board */}
        </div>

        <div className="min-w-[200px] flex items-center justify-center cursor-pointer   h-[50px] border-[1px] rounded-[20px] bg-[#faf9f9a4]">
          + Add another column
        </div>
      </div>
      {/* End of Sprint Board Section */}
      {/**Modal components for Create sprint and add user story */}
      <Modal isOpen={isCreate} position="center" handleClose={closeCreate}>
        <div className="my-5">
          <span className="inline-block text-lg text-background-2  mb-5">
            Create Sprint
          </span>

          <form>
            <div className="flex flex-col">
              <Input label="Sprint Title" placeholder="sprint 1" />
            </div>
            <div className="flex flex-col">
              <TextArea
                label="Sprint Description"
                placeholder="write a short descriptiion on what you want to do."
              />
            </div>
          </form>
          <Button color="bg-primary" className="text-white mt-10">
            Create Sprint
          </Button>
        </div>
      </Modal>
      Edit User
    </div>
  );
};

export default SprintState;
