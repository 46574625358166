import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import http from "../../lib/http";

const signUp = createAsyncThunk(
  "authentication/signUp",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.post({ url: "/auth/register", body });
      res.data?.auth_token &&
        localStorage.setItem("userToken", res.data?.auth_token);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const login = createAsyncThunk(
  "authentication/login",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.post({ url: "/auth/login", body });
      res.data?.auth_token &&
        localStorage.setItem("userToken", res.data?.auth_token);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateProfile = createAsyncThunk(
  "authentication/updateProfile",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.post({ url: "/settings/profile", body });
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updatePassword = createAsyncThunk(
  "authentication/changePassword",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.post({ url: "/settings/password", body });
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    isLoggedIn: false,
    loginError: null,
    token: null,
    userProfile: {},
    error: false,
  },
  reducers: {
    logout: (state) => {
      localStorage.clear();
      return state;
    },
  },
  extraReducers: {
    // SIGN UP
    [signUp.pending]: (state) => {
      state.loading = true;
    },
    [signUp.fulfilled]: (state, { payload }) => {
      state.token = payload.data.auth_token;
      state.userProfile = payload.data?.user;
      state.loading = false;
    },
    [signUp.rejected]: (state) => {
      state.loading = false;
    },

    // LOGIN
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.token = payload.data.auth_token;
      state.userProfile = payload.data?.user;

      state.loading = false;
    },
    [login.rejected]: (state, { payload }) => {
      state.loginError = true;
      state.loading = false;
    },

    //Update profile
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      toast.success(payload?.message);
      state.loading = false;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.loginError = true;
      state.loading = false;
    },

    //Update password
    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      toast.success(payload?.message);

      state.loading = false;
    },
    [updatePassword.rejected]: (state, { payload }) => {
      state.loginError = true;
      state.loading = false;
    },
  },
});

const { logout } = authSlice.actions;
export { signUp, login, logout, updateProfile, updatePassword };

export default authSlice.reducer;
