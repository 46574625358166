import Label from "./Label";

const TextArea = ({
  label,
  id,
  placeholder,
  className,
  required,
  register,
  name,
  errors = {},
}) => {
  const registerInput = register ? { ...register(name ?? "") } : null;
  return (
    <div>
      <Label id={id} required={required}>
        {label}
      </Label>
      <textarea
        className={`w-full px-5 py-5 rounded z-0 text-sm border text-grey-light  focus:ring-1 focus:outline-none ${className} ${
          errors[name ?? "na"]
            ? "border-red-500"
            : "border-grey-light focus:ring-primary-lighter"
        }`}
        rows={3}
        placeholder={placeholder}
        {...registerInput}
      />
    </div>
  );
};

export default TextArea;
