 
import { Logo } from "../../components";
import Slider from "../../components/Slider";
import { Link } from "react-router-dom";
import { LoginPath } from "../../constants/paths";
import { ArrowLe } from "../../components/Google";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const NewPassword = () => {
  const schema = yup.object({
    password: yup
      .string()
      .min(8)
      .required("Password must contain atleast 8 characters"),
    confirmPassword:yup.string().oneOf([yup.ref('password'), null])
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => { console.log(data) }
  
  return (
    <div className="flex justify-between h-full ">
      <div className="flex-[1.1] xl:w-[60%] xl:absolute">
        <Logo className="pl-[16px] mb-8 lg:pl-[7.5rem] pt-[3rem]" />
        <div className="flex flex-col justify-center items-center lg:ml-20">
          <div className="text-transparent font-libre bg-clip-text bg-gradient-to-r from-color1 to-color2  font-bold text-4xl pb-5">
            New Password
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">Password</label>
              <input
                type="text"
                className="w-[340px] sm:w-[450px] h-[50px] focus:outline-none focus:border-primary lg:w-[500px] lg:h-[60px] pl-6 text-[12px] border-solid border-[0.5px] border-[#87838C] rounded-2xl"
                name="password"
                placeholder="you@example.com"
                {...register("password")}
              />
              <div className=" mt-2">
                <p className="text-[#FF3B3B] text-end text-[12px] font-normal">
                    {errors.password?.errors.password.message}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-bold text-sm pt-8 pb-2">
                Confirm Password
              </label>
              <input
                className="w-[340px] sm:w-[450px] h-[50px] focus:outline-none focus:border-primary lg:w-[500px] lg:h-[60px] pl-6 text-sm border-solid border-[0.5px] border-[#87838C] rounded-2xl"
                type="password"
                placeholder="Confirm password"
                name="confirm password"
                {...register("confirmPassword")}
              />
              {errors.confirmPassword && 'password should match'}
            </div>
              <button className="btn-secondary-large pt-4" type="submit">Reset Password</button>
          </form>

           

          <Link to={LoginPath}>
            <div className="mt-5 flex">
              <span className="pt-1">
                <ArrowLe />
              </span>
              <span>
                <h5>Back to log in</h5>
              </span>
            </div>
          </Link>
        </div>
      </div>

      <Slider />
    </div>
  );
};

export default NewPassword;
