import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const Tabs = ({ list, activeSprint, setActiveSprint }) => {
  // const [activeSprint, setActiveSprint] = useState(0);
  const sprint = (index) =>
    activeSprint === index ? "bg-primary-light-4" : "neutral";

  return (
    <>
      <div className="flex">
        {list?.projectSprints?.map((props, index) => {
          return (
            <p
              key={index}
              className={`text-[14px] font-medium px-4 pb-2 cursor-pointer ${
                activeSprint === index
                  ? "text-primary border-b-4 border-primary"
                  : ""
              }`}
              size="small"
              color={sprint(index)}
              onClick={() => setActiveSprint(index)}
            >
              Sprint {index + 1}
            </p>
          );
        })}
      </div>
    </>
  );
};

const TabMove = ({ tabs, activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const param = searchParams.get("tab");

  const onTab = (alias) => {
    setActiveTab(alias);
    navigate(`${location.pathname}?tab=${alias}`);
  };

  useEffect(() => {
    if (tabs?.some((tab) => tab.alias === param)) {
      setActiveTab(param);
    } else {
      setActiveTab(tabs[0].alias);
    }
  }, [setActiveTab, param, tabs]);

  return (
    <>
      <div className=" flex mb-[3rem] pt-[1rem] relative">
        {tabs?.map((tab, i) => (
          <div
            onClick={() => onTab(tab.alias)}
            key={i}
            className={`${
              activeTab === tab.alias
                ? "text-primary border-b-2 border-primary relative"
                : ""
            }  text-[24px] font-medium px-4 relative pb-2 mx-[1rem] cursor-pointer`}
          >
            {tab.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default TabMove;
