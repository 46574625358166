import React from "react";
import { Card } from "../ui";
import circle from "../../assets/icons/circle.svg";
const BoardItem = ({ item }) => {
  // const onDragStart = (value) => {
  //   console.log({ value });
  // };
  const onDragStart = ({ dataTransfer, target }) => {
    dataTransfer.setData("item", JSON.stringify(item));
    // setDragElement(item);
    setTimeout(() => {
      target.style.visibility = "hidden";
    }, 1);
  };

  const onDragOver = (e) => {
    // moveItem(e.target.innerText);

    e.preventDefault();
  };
  return (
    <Card
      className="text-sm my-2 py-3 px-3 rounded-[4px] cursor-pointer shadow-md"
      draggable="true"
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      // onDragEnd={onDragEnd}
    >
      <p className=" text-[14px] font-medium">{item?.title}</p>
      <p className=" text-[12px] font-normal ">{item?.story}</p>
      <div className="flex justify-between">
        <p className=" text-[12px] font-light">
          {item?.date ?? item?.createdAtHuman}
        </p>
        <p className=" text-[12px] flex font-light gap-2">
          5% complete <img src={circle} alt="no img" />
        </p>
      </div>
    </Card>
  );
};

export default BoardItem;
