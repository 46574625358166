import React from "react";
import { Button, Spinner } from "../ui";

const AutomaticStory = ({ loading, generateWithAi }) => {
  return (
    <div>
      Generate using AI
      <Button
        className="w-48 text-white mt-10 normal-case rounded-xl"
        onClick={() => generateWithAi()}
      >
        {loading ? <Spinner /> : "Generate user story"}
      </Button>
    </div>
  );
};

export default AutomaticStory;
