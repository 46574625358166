import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "../components/ui";
import SprintState from "../components/sprint/SprintState";
import GenerateUserStory from "../components/story/GenerateUserStory";
import { userStorySchema } from "../validations/schema";
import { useDispatch, useSelector } from "react-redux";

import {
  createUserStoryWithAI,
  createUserStory,
} from "../features/user-story/userStory";
import { getAProject } from "../features/projects/projectSlice";
import Breadcrumbs from "../components/ui/Breadcrumbs";
const SprintPlaning = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(userStorySchema), mode: "all" });

  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [stories, setStories] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = handleSubmit((body) => {
    setIsLoading(true);
    dispatch(createUserStory({ projectId: project?.uuid, body })).then(
      ({ payload }) => {
        if (payload?.success) {
          setIsLoading(false);
          setIsOpen(false);
          reset();
          return dispatch(getAProject(project?.uuid)).then((res) => {
            setStories(res?.payload?.data?.userStories);
          });
        }
        setIsOpen(false);
        setIsLoading(false);
      }
    );
  });

  const generateWithAi = () => {
    setIsLoading(true);
    dispatch(createUserStoryWithAI(project?.uuid)).then(({ payload }) => {
      if (payload?.success) {
        setIsLoading(false);
        setIsOpen(false);
        return dispatch(getAProject(project?.uuid)).then((res) => {
          setStories(res?.payload?.data?.userStories);
        });
      }
      setIsOpen(false);
      setIsLoading(false);
    });
  };

  return (
    <>
    <Breadcrumbs/>
    <div className="overflow-hidden mx-[-32px]">
      <SprintState stories={stories} setStories={setStories} />

      {/**Modal components for Create sprint */}
      <Modal className="w-[549px]" isOpen={isOpen} handleClose={handleClose}>
        <GenerateUserStory
          register={register}
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          generateWithAi={generateWithAi}
        />
      </Modal>


    </div>
    </>
  );
};

export default SprintPlaning;
