import { Card } from "../ui";

const COLORS = [
  "bg-[#E3D8F3]",
  "bg-[#CCE1FF]",
  "bg-[#CEFDE9]",
  "bg-[#FFF5CC]",
  "bg-[#F9D2D2]",
];
const EmptyStoryState = () => {
  return (
    <>
      {COLORS.map((color, index) => (
        <Card
          key={index}
          className={`basis-64 shrink-0 min-h-[262px] ${color}`}
        />
      ))}
    </>
  );
};

export default EmptyStoryState;
