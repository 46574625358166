import { useSelector } from "react-redux";
import { Logo } from "../../components";
import { Button } from "../../components/ui";
import Items from "./Items";

const Sidebar = () => {
  const { sidenav } = useSelector((state) => state.action);
  // console.log(sidenav)
  return (
    <div
      className={`${
        sidenav ? "xl:ml-[-250px]  transition-all ease-linear duration-500" : ""
      } px-5 bg-white duration-500 fixed overflow-y-auto h-full scrollbar-hide  backdrop-blur-[103.681px] pt-12 pb-6 md:w-[25%] xl:w-[17%]`}
    >
      <Logo className={`mb-24`} />
      <Items />

      <div
        className={`bg-gradient-to-r from-primary to-primary-light rounded-3xl pt-14 pb-6 px-4  mt-32 
        `}
      >
        <p className="text-center text-white text-xl">
          Upgrade to PRO for more features
        </p>
        <Button
          className="text-background-1 mt-16 leading-6 font-[450]"
          size="small"
          color="bg-white"
          fullWidth
        >
          Upgrade
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
