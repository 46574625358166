import { Backdrop } from '../../components/ui';
import { Logo } from '../../components';

import Items from './Items';

const SideDrawer = ({ open, closed }) => {
  return (
    <>
      <Backdrop show={open} clicked={closed} />
      <div
        className={`fixed w-[50%]  h-full top-0 left-0 right-0 bg-white px-5 py-8 z-50 shadow-small transition-transform duration-300 ease-out lg:hidden ${
          open ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <Logo className="mb-24" />
        <Items />
      </div>
    </>
  );
};

export default SideDrawer;
