import React from "react";
import avatar from "../assets/images/avatar.svg";
import stars from "../assets/icons/stars.svg";
import save from "../assets/icons/save.svg";
import comment from "../assets/icons/comment.svg";
import shares from "../assets/icons/shares.svg";
import heart from "../assets/icons/heart.svg";
import dots from "../assets/icons/dots.svg";
import clock from "../assets/icons/clock.svg";
import vw from "../assets/icons/vw.svg";
import color from "../assets/images/color.svg";

const ViewPost = () => {
  const Stories = [{}, {}];

  const images = [
    { img: color },
    { img: color },
    { img: color },
    { img: color },
    { img: color },
  ];

  return (
    <div className="lg:w-[823px] w-full pt-6 mx-auto">
      <div className="flex justify-between">
        <span>
          <img src={vw} alt="no img" />
        </span>
        <span className="w-[100px] rounded-sm h-[40px] border  flex items-center justify-center text-sm font-sans">
          Visit Site
        </span>
      </div>
      <h3 className="font-sans font-medium text-2xl">Amazon Book App</h3>
      <div className="flex justify-between mb-4">
        <p className="text-sm font-sans">Productivity</p>
        <span className="flex w-[50%] justify-between items-center ">
          <span className="text-xs flex text-background-2">
            <img src={comment} className="cursor-pointer" alt="no img" />
            <span className="text-[14px] ml-1">Comment </span>
          </span>
          <span className="text-xs flex text-background-2">
            <img src={save} className="cursor-pointer" alt="no img" />
            <span className="text-[14px] ml-1">Save</span>
          </span>
          <span className="text-xs flex text-background-2">
            <img src={shares} className="cursor-pointer" alt="no img" />
            <span className="text-[14px] ml-1">Share </span>
          </span>
          <span className="text-xs w-auto flex text-background-2">
            <img src={stars} className="cursor-pointer" alt="no img" />
            <span className="text-[14px]  ml-1">Rate Product </span>
          </span>
        </span>
      </div>
      <div className="text-sm font-sans">
        Lorem ipsum dolor sit amet consectetur. Volutpat duis eget porttitor
        auctor sit enim. Nisl potenti tellus mattis gravida ultrices et tortor
        elementum tempor. Id tortor non massa mi nec lacus tempus. Nullam
        viverra vestibulum accumsan nisi. Turpis facilisi sodales nulla ac
        venenatis dignissim consequat faucibus nisl. Enim in at nisl nec
        ultrices. Leo in aliquam at interdum felis volutpat. Vel eleifend mi et
        pretium tristique rhoncus montes hendrerit. Consequat consectetur
        sodales quam libero varius magna mattis vitae. Velit augue purus
        ullamcorper velit.
      </div>
      <div className="flex max-w-full overflow-x-auto gap-2 mt-10">
        <div className="flex h-[220px]">
          {images.map((props) => {
            return (
              <div key={props.id} className="w-[292px] mx-2 ">
                <img src={props.img} alt="img" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="border border[#87838C] h-[72px] mt-7 rounded-lg py-3 px-4">
        <div className="flex items-center justify-between">
          <div className="flex">
            <div className="h-[28px] mr-2 w-[28px] md:h-[36px] md:w-[36px]">
              <img
                className="w-full h-full"
                src={avatar}
                alt="profile picure"
              />
            </div>
            <input
              placeholder="What do you have to say about this product?"
              className="border-none w-[350px] border-transparent !outline-none"
            />
          </div>
          <span className="w-[108px] h-[44px] rounded-md border flex items-center justify-center text-sm font-sans">
            Comment
          </span>
        </div>
      </div>
      {Stories.map((props) => {
        return (
          <div className="mt-9" key={props.id}>
            <div className="flex w-[95%] mx-auto items-center mb-4">
              <span className="h-[28px] mr-2 w-[28px] md:h-[36px] md:w-[36px]">
                <img
                  className="w-full h-full"
                  src={avatar}
                  alt="profile picure"
                />
              </span>
              <span className="text-sm mx-3 font-medium font-sans">
                Tory Lanze
              </span>
              <span className="cursor-pointer w-[64px] h-[28px] text-xs bg-yellow-300 flex rounded-full justify-center items-center">
                {" "}
                Review
              </span>
            </div>
            <div className="w-[675px] mx-auto">
              <div className="text-base font-sans">
                Lorem ipsum dolor sit amet consectetur. Id risus vitae mattis
                urna lectus varius natoque enim amet. Lobortis tempus sapien dui
                aliquam enim. Sed feugiat sollicitudin viverra urna leo erat in
                vitae nullam. Integer risus et non accumsan venenatis. Venenatis
                at nullam nibh porta pulvinar nisi. 👏🏽👏🏽👏🏽
              </div>
              <div className="flex">
                <span className="text-xs flex mr-4 text-background-2">
                  <img src={comment} className="cursor-pointer" alt="no img" />
                  <span className="text-[14px] ml-2">120 </span>
                </span>
                <span className="text-xs flex mr-4 text-background-2">
                  <img src={heart} className="cursor-pointer" alt="no img" />
                  <span className="text-[14px] ml-2">90 </span>
                </span>
                <span className="text-xs flex mr-4 text-background-2">
                  <img src={shares} className="cursor-pointer" alt="no img" />
                  <span className="text-[14px] ml-2">4 </span>
                </span>
                <span className="text-xs flex mr-4 text-background-2">
                  <img src={clock} className="cursor-pointer" alt="no img" />
                  <span className="text-[14px] ml-2">5h </span>
                </span>
                <span>
                  <img src={dots} className="cursor-pointer" alt="no img" />
                </span>
              </div>
              <div className="grid bg-[#999999] ml-2 mt-2 h-[52px] w-[1px]"></div>

              <div className="mt-3 ml-[-0.5rem] ">
                <div className="flex w-[100%] items-center mb-4">
                  <span className="h-[28px] mr-2 w-[28px] md:h-[36px] md:w-[36px]">
                    <img
                      className="w-full h-full"
                      src={avatar}
                      alt="profile picure"
                    />
                  </span>
                  <span className="text-sm mx-3 font-medium font-sans">
                    Anita Jones
                  </span>
                  <span className="cursor-pointer w-[64px] h-[28px] text-xs bg-yellow-300 flex rounded-full justify-center items-center">
                    {" "}
                    Review
                  </span>
                </div>
                <div className="w-[650px] pl-[3.5rem] ">
                  <div className="text-base font-sans">
                    Lorem ipsum dolor sit amet consectetur. Id risus vitae
                    mattis urna lectus varius natoque enim amet. Lobortis tempus
                    sapien dui aliquam enim. Sed feugiat sollicitudin viverra
                    urna leo erat in vitae nullam. Integer risus et non accumsan
                    venenatis. Venenatis at nullam nibh porta pulvinar nisi.
                    👏🏽👏🏽👏🏽
                  </div>
                  <div className="flex">
                    <span className="text-xs flex mr-4 text-background-2">
                      <img
                        src={comment}
                        className="cursor-pointer"
                        alt="no img"
                      />
                      <span className="text-[14px] ml-2">120 </span>
                    </span>
                    <span className="text-xs flex mr-4 text-background-2">
                      <img
                        src={heart}
                        className="cursor-pointer"
                        alt="no img"
                      />
                      <span className="text-[14px] ml-2">90 </span>
                    </span>
                    <span className="text-xs flex mr-4 text-background-2">
                      <img
                        src={shares}
                        className="cursor-pointer"
                        alt="no img"
                      />
                      <span className="text-[14px] ml-2">4 </span>
                    </span>
                    <span className="text-xs flex mr-4 text-background-2">
                      <img
                        src={clock}
                        className="cursor-pointer"
                        alt="no img"
                      />
                      <span className="text-[14px] ml-2">5h </span>
                    </span>
                    <span>
                      <img src={dots} className="cursor-pointer" alt="no img" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewPost;
