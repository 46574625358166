import * as yup from "yup";

export const registerSchema = yup
  .object({
    // name: yup.string().required("Full Name is a required field"),
    // companyName: yup.string().required("Company name is a required field"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
    password: yup
      .string()
      .required("Password is a required field")
      .min(8, "Password must be atleast 8 characters"),
  })
  .required();

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
    password: yup.string().required("Password is a required field"),
  })
  .required();

export const createProjectSchema = yup
  .object({
    title: yup.string().required("Project title is a required field"),
    description: yup.string().required("Description is an required field"),
  })
  .required();

export const CreatePostSchema = yup
  .object({
    productTitle: yup.string().required("product title field is required"),
    companySector: yup.string().required("copmany sector field is required"),
    productType: yup.string().required("product type field is required"),
    productDescription: yup
      .string()
      .required("copmany sector field is required"),
  })
  .required();
export const userStorySchema = yup
  .object({
    asA: yup.string().required("Project title is a required field"),
    iWantTo: yup.string().optional("Description is an required field"),
    contextA: yup.string().optional("Description is an required field"),
    additionalTextA: yup.string().optional("Description is an required field"),
    acceptance_criteria: yup
      .string()
      .optional("Description is an required field"),
    soICan: yup.string().optional("Description is an required field"),
  })
  .required();
