const Stack = ({ direction = 'column', gap=0, justifyContent, alignItems, className='', children }) => {
  const flexDirection = direction === 'column' ? 'flex-col' : null;
  const flexGap = `gap-${gap}`;
    const flexJustify = `justify-${justifyContent}`;
    const flexItems = `items-${alignItems}`
  return (
    <div
      className={`flex ${flexDirection} ${flexGap} ${flexJustify} ${flexItems} flex-wrap ${className}`}
    >
      {children}
    </div>
  );
};

export default Stack;
