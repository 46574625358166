import React from "react";
import { Button } from "../ui";
import { ManualForm } from "../story";
import AutomaticStory from "./AutomaticStory";

const GenerateUserStory = ({
  register,
  onSubmit,
  errors,
  loading,
  generateWithAi,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const activeColor = (index) =>
    activeTab === index ? "bg-primary-light-4" : "neutral";
  return (
    <div className="my-3">
      <span className="inline-block text-lg text-background-2  mb-5">
        How do you want to create your user stories?
      </span>
      <div className="flex gap-4 w-full mb-5">
        <Button
          className={`w-1/2 text-base ${activeTab === 0 ? "text-primary" : ""}`}
          size="small"
          color={activeColor(0)}
          onClick={() => setActiveTab(0)}
        >
          Manually
        </Button>
        <Button
          color={activeColor(1)}
          className={`w-1/2 text-base ${activeTab === 1 ? "text-primary" : ""}`}
          size="small"
          onClick={() => setActiveTab(1)}
        >
          Automatically
        </Button>
      </div>

      {/* MANUAL PROCESS CONTENT */}
      {activeTab === 0 ? (
        <ManualForm
          register={register}
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
        />
      ) : (
        <AutomaticStory generateWithAi={generateWithAi} loading={loading} />
      )}
    </div>
  );
};

export default GenerateUserStory;
