import ReactDOM from 'react-dom';
import close from '../../assets/icons/close.svg';

const Backdrop = ({ close }) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-[100vh] z-50 bg-black opacity-70"
      onClick={close}
    ></div>
  );
};

const Modal = ({ isOpen, handleClose, position, className, children }) => {
  let positionClass = 'top-[10%]';

  if (position === 'center') {
    positionClass = 'top-[50%] -translate-y-2/4';
  }
  if (!isOpen) return null;
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop close={handleClose} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <div
          className={`fixed ${positionClass} left-[50%] -translate-x-2/4 min-w-[460px] max-h-[80%] px-6 py-5 bg-white z-50 overflow-y-auto ${className}`}
        >
          <img
            src={close}
            alt="Close icon"
            className="absolute right-6 cursor-pointer"
            onClick={handleClose}
          />
          {children}
        </div>,
        document.getElementById('modal-root')
      )}
    </>
  );
};

export default Modal;
