import React, { useState } from 'react'
import {Image, ArrowLeft, ArrowRight } from './Google';

const Slider = () => {
    const [ setCurrentIndex] =useState(0)
    const handleOnNextClick = () => {
        setCurrentIndex(previousIndex => previousIndex  + 1)
    }
    const handleOnPrevClick = () => {
        setCurrentIndex(previousIndex => previousIndex - 1)
    }
    return (
        <div className="hidden xl:flex  xl:w-[606px] bg-gradient-to-r from-color1 to-color2 h-screen fixed right-0 ">
        <div className=' lg:w-full lg:h-screen lg:flex flex-col items-center select-none'>
            <div className='mt-[8.5rem]' ><Image /></div>
          <div className='w-[500px] text-white text-center mt-[6rem]'>
                <h5 className=' text-[16px] font-bold'>Generate User Stories</h5>
                <p className='text-sm'> User stories are a great way to structure your product backlog,
                    but it can be tough to determine what each user story is.</p>
            </div>
            <div className='absolute w-[500px] top-[95%] transform -translate-y-1/2 px-3 flex justify-between'>
          
                <button onClick={handleOnPrevClick}><ArrowLeft /></button>
                
                <button onClick={handleOnNextClick}><ArrowRight/></button>
             </div>
        </div></div>
    )
}

export default Slider