import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../lib/http";
import { Projects } from "../../constants/api-urls";
import { toast } from "react-toastify";

const createUserStory = createAsyncThunk(
  "userStory/create",
  async ({ projectId, body }, { rejectWithValue }) => {
    try {
      return await http.post({
        url: `${Projects}/${projectId}/user-stories`,
        body,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateUserStory = createAsyncThunk(
  "userStory/update",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.patch({
        url: `${Projects}/${body.projectId}/user-stories/${body.storyId}`,
        body: { progress_uuid: body.progressId },
      });

      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createUserStoryWithAI = createAsyncThunk(
  "userStory/Generate",
  async (projectId, { rejectWithValue }) => {
    try {
      return await http.put({
        url: `${Projects}/${projectId}/user-stories/generate`,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const userStorySlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    userStory: {},
    userStories: [],
  },
  reducers: {
    logout: (state) => {
      localStorage.clear();
      return state;
    },
  },
  extraReducers: {
    [createUserStory.pending]: (state) => {
      state.loading = true;
    },
    [createUserStory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload?.message);
    },
    [createUserStory.rejected]: (state) => {
      state.loading = false;
    },

    [updateUserStory.pending]: (state) => {
      state.loading = true;
    },
    [updateUserStory.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateUserStory.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // Generate user story with AI
    [createUserStoryWithAI.pending]: (state) => {
      state.loading = true;
    },
    [createUserStoryWithAI.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload?.message);
    },
    [createUserStoryWithAI.rejected]: (state, { payload }) => {
      state.loading = false;

      toast.error("Error!");
    },
  },
});

export { createUserStory, updateUserStory, createUserStoryWithAI };

export default userStorySlice.reducer;
