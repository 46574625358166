import { Modal, SearchInput } from "../components/ui";
import avatar from "../assets/images/avatar.svg";
import hamburger from "../assets/icons/menu-icon.svg";
import invite from "../assets/icons/nav-invite-icon.svg";
import notifications from "../assets/icons/notifications.svg";
import people from "../assets/icons/people.svg";
import message from "../assets/icons/message.svg";
import bell from "../assets/icons/bell.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import InviteTeam from "../components/sprint/InviteTeam";

const Navbar = ({ openSideDrawer }) => {
  const { sidenav } = useSelector((state) => state.action);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const {
    auth: { userProfile },
  } = useSelector((state) => state);
  return (
    <header className="">
      <nav
        className={`${
          sidenav ? "lg:w-[100%] duration-500" : "lg:w-[83%]"
        } duration-500 flex items-center justify-between py-4 z-10 md:fixed md:bg-white md:w-[75%]  md:px-6`}
      >
        <div className="md:hidden" onClick={openSideDrawer}>
          <img src={hamburger} alt="Hamburger menu icon" />
        </div>
        <div className="hidden md:block text-lg lg:text-xl text-background-2">
          Dashboard
        </div>
        <div className="hidden md:block w-[37%]">
          <SearchInput type="text" placeholder="Search project" />
        </div>
        <div className="flex items-center gap-4 md:gap-0 lg:gap-6">
          <div
            className="hidden md:flex cursor-pointer items-center gap-2"
            onClick={handleClick}
          >
            <img src={people} alt="" />
            <span>Invite people</span>
          </div>
          <span className="md:hidden">
            <img src={invite} alt="Invite icon" />
          </span>
          <img className="hidden md:block" src={message} alt="message" />
          <img className="hidden md:block" src={bell} alt="notification" />
          <img
            className="md:hidden"
            src={notifications}
            alt="Notifications icon"
          />
          <div className="flex items-center gap-2">
            <div className="h-[28px] w-[28px] md:h-[36px] md:w-[36px]">
              <img
                className="w-full h-full"
                src={`${userProfile?.avatar ?? avatar}`}
                alt="profile picure"
              />
            </div>
            <div className="hidden lg:flex flex-col">
              <span className="text-sm">
                {" "}
                {userProfile?.firstName && userProfile?.lastName && (
                  <span>{`${userProfile?.firstName} ${userProfile?.lastName}`}</span>
                )}
              </span>
              <span className="text-[12px] text-[#999999]">
                Account{" "}
                <span className="bg-[#F4F0FA] text-primary p-1 rounded">
                  {`${userProfile?.currentPlan?.name}`}
                </span>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <Modal
        isOpen={open}
        className="w-[684px]"
        position="center"
        handleClose={handleClick}
      >
        <InviteTeam />
      </Modal>
    </header>
  );
};

export default Navbar;
