import axios from "axios";
import queryString from "query-string";

class HttpFacade {
  http;
  constructor() {
    this.http = axios.create({
      baseURL: "https://uat.requstory.com/api/v1",
      headers: { "Content-Type": "application/json" },
    });

    this.http.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("userToken");
        if (token && config.headers) {
          config.headers.Authorization = "Bearer " + token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error?.response?.status)) {
          // window.location.replace("/");
        }
        return Promise.reject(error.response);
      }
    );
  }

  post = async ({ url, body }) => {
    const response = await this.http.post(url, body);
    return response.data;
  };

  patch = async ({ url, body }) => {
    const response = await this.http.patch(url, body);
    return response.data;
  };

  get = async ({ url, query = {} }) => {
    const queryStringified = queryString.stringify(query);
    const response = await this.http.get(`${url}?${queryStringified}`);
    return response.data;
  };

  delete = async ({ url }) => {
    const response = await this.http.delete(url);
    return response.data;
  };

  put = async ({ url, body }) => {
    const response = await this.http.put(url, body);
    return response.data;
  };
}

export default new HttpFacade();
