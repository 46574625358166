import { Card, Button } from "./ui";
import { Stack } from "./layout";
import eye from "../assets/icons/eye.svg";
import star from "../assets/icons/star.svg";
import text from "../assets/icons/text.svg";
 

const ProductCard = ({ project }) => {
  return (
    <Card className="min-h-[341px] pb-5">
      <img src={project.image} className="w-full mb-4" alt="no img" />

      <Stack className="px-5">
        <Stack
          className="mb-5"
          direction="row"
          justifyContent="between"
          alignItems="center"
        >
          <span className="text-[16px] font-sans font-normal ">Apple Inc</span>
          <span className="text-[14px] font-sans text-[#BDBDBD]">
            Technolgy
          </span>
        </Stack>
        <Stack className="text-[14px] mb-7 font-sans" alignItems="center">
          Lorem ipsum dolor sit amet consectetur. Quis enim viverra lacus
          commodo. Aenean nisl psque viverra magna in ipsum. Et diam ac
          malesuada lacus facilisis purus ulis ut.
        </Stack>
        <Stack direction="row" justifyContent="between" alignItems="center">
          <span className="text-xs flex text-background-2">
            <img src={text} alt="no img" />
            <span className="text-[14px] ml-1">50 </span>
          </span>
          <span className="text-xs flex text-background-2">
            <img src={eye} alt="no img" />
            <span className="text-[14px] ml-1">350 </span>
          </span>
          <span className="text-xs flex text-background-2">
            <img src={star} alt="no img" />
            <span className="text-[14px] ml-1">4.0 </span>
          </span>
          <Button
            color="red"
            className="text-white bg-primary "
          >
            Review Project
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProductCard;
