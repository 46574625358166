const Label = ({ id, required, children }) => {
  return (
    <label htmlFor={id} className="inline-block mb-2 text-lg text-background-2">
      {children}
      {required && <span className="text-[#FF0000]">*</span>}
    </label>
  );
};

export default Label;
