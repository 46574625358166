import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Input,
  TextArea,
  FileUpload,
  // Label,
  Button,
  Spinner,
} from "../components/ui";
import { UserNewProjectPath } from "../constants/paths";
import { createProjectSchema } from "../validations/schema";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../features/projects/projectSlice";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import DateInput from "../components/ui/DateInput";

const CreateProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    // control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createProjectSchema), mode: "all" });

  const { loading } = useSelector((state) => state.project);
  // const [show, setShow] = useState(false);

  return (
    <div className="mt-10">
      <h1 className="text-[32px] text-background-2">Create your project</h1>
      <div className="flex flex-col gap-5 mt-8">
        <Input
          errors={errors}
          register={register}
          name="title"
          type="text"
          id="title"
          label="Project Title"
          placeholder="Give your project a name."
          required
        />
        <TextArea
          errors={errors}
          register={register}
          name="description"
          label="Project Description"
          placeholder="A short description of your project"
        />
        <FileUpload
          label="Upload project logo (optional)"
          subText="Image should be at least 500 pixels wide and only *.jpg, *.png and *.jpeg formats are accepted."
        />
        {/* <div>
          <div className={`${show ? "hidden" : "flex"}`}>
            <Label required>Project schedule</Label>
          </div>
          <div className={`${show ? "flex" : "hidden"} flex`}>
            <span>
              <DateInput
                control={control}
                errors={errors}
                register={register}
                name="date"
                type="date"
                id="title"
                label="Start Date"
                placeholder="dd/mm/yy."
              />
            </span>
            <span className="ml-9">
              <DateInput
                control={control}
                errors={errors}
                register={register}
                name="date"
                type="date"
                id="title"
                label="End Date"
                placeholder="dd/mm/yy."
              />
            </span>
          </div>
          <span
            onClick={() => setShow(!show)}
            className={`${
              show ? "hidden" : "flex"
            } block font-sm underline cursor-pointer text-grey-light font-light`}
          >
            Add start/end dates
          </span>
          <span
            onClick={() => setShow(!show)}
            className={`${
              show ? "flex" : "hidden"
            } block font-sm underline cursor-pointer text-grey-light font-light`}
          >
            Remove Dates
          </span>
        </div> */}
        <div className="w-[200px] mt-5">
          <Button
            fullWidth
            onClick={handleSubmit((data) => {
              dispatch(createProject(data)).then((res) => {
                if (res.payload?.success) {
                  navigate(`${UserNewProjectPath}`, {
                    state: { data: res.payload?.data },
                  });
                }
              });
            })}
          >
            {loading ? <Spinner /> : "Create Project"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
