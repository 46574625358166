import Label from "./Label";

const Input = ({
  name = "",
  type,
  id,
  label,
  placeholder,
  className,
  required,
  register,
  errors = {},
}) => {
  const registerInput = register ? { ...register(name ?? "") } : null;
  return (
    <div>
      <Label id={id} required={required}>
        {label}
      </Label>
      <input
        id={id}
        type={type}
        className={`h-14 w-full px-5 rounded z-0 text-sm border ${className} ${
          errors[name]
            ? "!border-red-500"
            : "border-grey-light focus:ring-primary-lighter"
        }
        
        
          text-grey-light  focus:ring-1 focus:outline-none ${className}`}
        placeholder={placeholder}
        {...registerInput}
      />
    </div>
  );
};

export default Input;
