import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../features/projects/projectSlice";

import {
  MdArrowForwardIos,
  MdOutlineFavoriteBorder,
  MdAddCircleOutline,
} from "react-icons/md";

import { AiOutlinePlus } from "react-icons/ai";

import { ProjectCard } from "../components";
import { Divider, Button, Card } from "../components/ui";
import { UserCreateProjectPath } from "../constants/paths";

const UserDashboard = () => {
  const [newUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    project: { projects },
    auth: { userProfile },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const projectMapper = projects
    .map((el) => ({
      uuid: el?.uuid,
      name: el?.projectTitle ?? "N/A",
      description: el?.projectDescription ?? "N/A",
      noOfActiveTasks: 4,
      date: "7th, Oct 2022",
    }))
    .slice(0, 3);

  return (
    <section className="overflow-hidden">
      {newUser ? (
        <div className="flex flex-col items-center text-center mt-6 lg:mt-20 mb-12">
          <h1 className="font-libre text-background-2 font-bold text-3xl sm:text-5xl mb-4">
            Hello {`${userProfile.firtsName ?? "There"}`}!
          </h1>
          <p className="text-base sm:text-xl text-[#666666] mb-10">
            Create a new project to get going.
          </p>
          <Button
            onClick={() => navigate(UserCreateProjectPath)}
            startIcon={<AiOutlinePlus size={24} />}
          >
            Create Project
          </Button>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mt-[52px] mb-8">
            <h1 className="font-libre text-background-2 font-bold text-3xl sm:text-5xl">
              Hello {`${userProfile.firtsName ?? "There"}`}!
            </h1>{" "}
            <Button
              onClick={() => navigate(UserCreateProjectPath)}
              startIcon={<AiOutlinePlus size={24} />}
            >
              Create Project
            </Button>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 mb-[40px]">
            {projectMapper.map((project, index) => (
              <ProjectCard key={index + 1} project={project} />
            ))}
          </div>
        </>
      )}

      <Divider className="max-w-[886px] mx-auto mb-12" />
      <section className="mb-[52px] lg:mb-20" id="features">
        <div className="flex justify-between lg:max-w-[92.5%] mb-9">
          <div>
            <h3 className="font-normal text-body text-sm mb-1">
              Take your user stories to the next level
            </h3>
            <p className="font-libre font-bold text-xl text-background-1">
              Explore Templates
            </p>
          </div>
          <div className="hidden sm:block">
            <Button
              className="font-bold text-grey-light"
              color="bg-primary-light-3"
              endIcon={<MdArrowForwardIos size={20} />}
            >
              View All
            </Button>
          </div>
        </div>
        {/* Cards */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <Card className="relative bg-primary-hover text-primary-light-4 pt-10 px-6 pb-5">
            <div className="flex justify-between items-center mb-8">
              <h4 className="font-bold text-2xl">Banking App.</h4>
              <MdOutlineFavoriteBorder size={24} />
            </div>
            <p className="font-normal text-base tracking-[0.07px] mb-20 min-h-[135px]">
              As a user, <br /> I want to be able to add details of my regular
              payment to my mobile app, So that they can be automated.
            </p>
            <div className="absolute bottom-6 right-6 left-6">
              <Button
                className="text-heading"
                color="bg-white"
                startIcon={<MdAddCircleOutline size={20} />}
                fullWidth
              >
                Use Template
              </Button>
            </div>
          </Card>
          <Card className="relative bg-success-darker text-primary-light-4 pt-10 px-6 pb-5">
            <div className="flex justify-between items-center mb-8">
              <h4 className="font-bold text-2xl">Activity Tracker.</h4>
              <MdOutlineFavoriteBorder size={24} />
            </div>
            <p className="font-normal  text-base tracking-[0.07px] mb-20 min-h-[135px]">
              As a user, <br /> I want to search for directions, using my
              current location as origination point with a custom destination,
              so that I can get directions quickly.
            </p>
            <div className="absolute bottom-6 right-6 left-6">
              <Button
                className="text-heading"
                color="bg-white"
                startIcon={<MdAddCircleOutline size={20} />}
                fullWidth
              >
                Use Template
              </Button>
            </div>
          </Card>
          <Card className="md:col-span-2 lg:col-span-1 relative bg-info-darker text-primary-light-4 pt-10 px-6 pb-5">
            <div className="flex justify-between items-center mb-8">
              <h4 className="font-bold text-2xl">Airbnb.</h4>
              <MdOutlineFavoriteBorder size={24} />
            </div>
            <p className="font-normal text-base tracking-[0.07px] mb-20 min-h-[135px]">
              As a user, <br /> I want to review the communication of the host
              (1-5) so that I feel assured the host will be responsive to my
              messages.
            </p>
            <div className="absolute bottom-6 right-6 left-6">
              <Button
                className="text-heading"
                color="bg-white"
                startIcon={<MdAddCircleOutline size={20} />}
                fullWidth
              >
                Use Template
              </Button>
            </div>
          </Card>
        </div>
      </section>
    </section>
  );
};

export default UserDashboard;
