import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../lib/http";
import { Projects } from "../../constants/api-urls";
import { toast } from "react-toastify";

const createProject = createAsyncThunk(
  "projects/create",
  async (body, { rejectWithValue }) => {
    try {
      return await http.post({ url: Projects, body });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const updateProject = createAsyncThunk(
  "projects/update",
  async (body, { rejectWithValue }) => {
    try {
      const res = await http.patch({ url: Projects, body });

      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getAProject = createAsyncThunk(
  "projects/getOne",
  async (id, { rejectWithValue }) => {
    try {
      return await http.get({ url: Projects + `/${id}` });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllProjects = createAsyncThunk(
  "projects/getAll",
  async (_, { rejectWithValue }) => {
    try {
      return await http.get({ url: Projects });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    loading: false,
    error: null,
    projects: [],
    project: {},
  },
  reducers: {
    logout: (state) => {
      localStorage.clear();
      return state;
    },
  },
  extraReducers: {
    // Create project
    [createProject.pending]: (state) => {
      state.loading = true;
    },
    [createProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.project = payload.data;
    },
    [createProject.rejected]: (state, payload) => {
      state.loading = false;
      state.error = payload?.error;
      toast.error("Please upgrade your account.");
    },

    // Update project
    [updateProject.pending]: (state) => {
      state.loading = true;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateProject.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // Get a project
    [getAProject.pending]: (state) => {
      state.loading = true;
    },
    [getAProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.project = payload.data;
    },
    [getAProject.rejected]: (state) => {
      state.loading = false;
    },
    // Get All projects
    [getAllProjects.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects = payload.data?.projects;
    },
    [getAllProjects.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export { createProject, updateProject, getAProject, getAllProjects };

export default projectSlice.reducer;
