import React from "react";
import { Button } from "../components/ui";
 
import ProductCard from "../components/ProductCard";
import apple from "../assets/images/apple.svg";
import asana from "../assets/images/asana.svg";
import tweet from "../assets/images/tweet.svg";
import { UserCreatePostPath } from "../constants/paths";
import { useNavigate } from "react-router-dom";
import BreadCrumbs2 from "../components/ui/BreadCrumbs2";

const ProductReview = () => {
  const navigate = useNavigate()
  const tabs = [
    { image: apple },
    { image: asana },
    { image: tweet },
    { image: apple },
    { image: asana },
    { image: tweet },
    { image: apple },
    { image: asana },
    { image: tweet },
  ];

  return (
    <>
     <BreadCrumbs2/>
      <div className="flex justify-between mb-9">
        <span className="text-background-2 text-xl">Recent Products</span>
        <Button className='' onClick={() => navigate(UserCreatePostPath)}>
            Create Post
          </Button>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {tabs.map((project, index) => (
          <ProductCard key={index + 1} project={project} />
        ))}
      </div>
    </>
  );
};

export default ProductReview;
