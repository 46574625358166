import React from "react";
import { Button, Input } from "../ui";
import down from '../../assets/icons/down.svg'
import padlock from '../../assets/icons/padlock.svg'
function InviteTeam() {
  return (
    <div>
      <div className="text-3xl font-sans font-bold">
        Invite your team to your project
      </div>
      <Input className="mt-8 mb-6" placeholder="Type email address" />
      <div className="flex justify-between items-center">
        <div className="font-sans flex text-sm">
          <span>Invite team as a </span>
          <span className="font-bold ml-1">Collaborator</span>
          <span className="pointer-cursor"><img src={down} alt='dropdown'/></span>
        </div>
        <div>
          <Button color="bg-primary" className=" text-white">
            Send Invite
          </Button>
        </div>
      </div>
      <div className="flex items-center">
        <span><img src={padlock} alt='padlock'/></span>
        <span className="font-sans">
            <div className="flex">
                <span className="font-bold">Restricted</span>
                <span><img src={down} alt='dropdown'/></span>
            </div>
            <div className="text-sm ">
            Only invited team members can have access 
            </div>
        </span>
      </div>
    </div>
  );
}

export default InviteTeam;
