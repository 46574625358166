import React, { useState } from "react";
import TabMove from "../components/Tabs";
import BIllings from "../features/accountSettings/BIllings";
import Profile from "../features/accountSettings/Profile";
import { useSelector } from "react-redux";
import BreadCrumbs2 from "../components/ui/BreadCrumbs2";

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { sidenav } = useSelector((state) => state.action);
  const tabList = [
    { name: "Profile", alias: "profile" },
    { name: "Billing", alias: "billing" },
  ];

  return (
    <>
      <BreadCrumbs2 />
      <div className=" lg:ml-[-3rem] relative">
        <div
          className={`${
            sidenav ? "h-[74px] w-[100vw] " : "w-[107%] h-[74px] "
          } h-[74px] ml-[-0.59rem] bg-white`}
        >
          <TabMove
            tabs={tabList}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === tabList[0].alias && <Profile />}
          {activeTab === tabList[1].alias && <BIllings />}
        </div>
        <div className="mb-[80rem]"></div>
      </div>
    </>
  );
};

export default AccountSettings;
