/* authentication paths */
export const LoginPath = "/";
export const RegisterPath = "/register";
export const LogoutPath = "/logout";
export const ForgotPasswordPath = "/forgotpassword";
export const NewPasswordPath = "/newpassword";
export const CheckEmailPath = "/checkemail";
export const ResetSuccessfulPath = "/resetsuccessful";

/* user module paths  */
export const UserSprintPath = "/user/dashboard/sprint";
export const UserStoriesPath = "/user/dashboard/stories";
export const UserAllSprintPath = "/user/dashboard/sprint/allsprint";
export const userWalletPath = "/user/dashboard/sprint/wallet";
export const UserCreateProjectPath = "/user/dashboard/create-project";
export const UserNewProjectPath = "/user/dashboard/plan-project";
export const UserCreatePostPath = "/user/dashboard/product-review/create-post";

// side nav paths
export const UserDashboardPath = "/user/dashboard";
export const UserProjectsPath = "/user/projects";
export const UserAccountSetingsPath = "/user/account-setting";
export const UserWorkspacePath = "/user/workspace";
export const UserProductReviewPath = "/user/product-review";
export const UserSupportPath = "/user/support";
