import { Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import chevrons from "../../assets/icons/chevrons.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidenav } from "../../features/action/actionSlice";
import { IconButton } from "./Button";
import updown from "../../assets/icons/updown.svg";
import close from "../../assets/icons/close.svg";
// import zap from "../../assets/icons/zap.svg";
import plus from "../../assets/icons/plus.svg";
import map from "../../assets/icons/map.svg";
import menu from "../../assets/icons/menu.svg";
import flash from "../../assets/icons/flash.svg";
import * as Paths from "../../constants/paths.js";

const defaultSeparator = (
  <img src={chevrons} className="" alt="no-img" width="16" height="16" />
);

const Breadcrumbs = ({ separator = defaultSeparator }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidenav } = useSelector((state) => state.action);

  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter((x) => x && x !== "user");

  // useEffect(()=>{
  //   if (Paths.UserSprintPath) return onToggleSideNav
  // },[])
  const onToggleSideNav = () => {
    dispatch(toggleSidenav(!sidenav));
  };
  const isLast = (index) => {
    return index === pathnames.length - 1;
  };
  if (pathnames.length === 1 && pathnames[0] === "dashboard") return null;

  const onNavigate = () => {
    dispatch(toggleSidenav(!sidenav));
    return navigate(Paths.UserSprintPath);
  };

  return (
    <>
      {sidenav ? (
        <ol className="hidden list-reset sm:flex items-center gap-3 text-base pt-4 pb-6">
          <div className="flex w-full justify-between">
            <div className="flex">
              <IconButton img={menu} text={"User Stories"} />
              <IconButton
                img={flash}
                text={"Sprint Plannng"}
                toggle={onNavigate}
              />
              <IconButton img={map} text={"Proccess Map"} />
            </div>
            <div className="flex">
              {/* <IconButton img={zap} text={"Automate sprint"} /> */}
              <IconButton img={plus} text={"Add Sprint"} />
              <IconButton toggle={onToggleSideNav} img={close} text={"Close"} />
            </div>
          </div>
        </ol>
      ) : (
        <ol className="hidden list-reset sm:flex items-center gap-3 text-base pt-4 pb-6">
          <li>
            <Link to="/user/dashboard" className="text-link text-[#999999]">
              Dashboard
            </Link>
          </li>
          {pathnames.map((name, index) => {
            if (name === "dashboard") return null;

            const activeLink = isLast(index);
            const updatedName = name.split("-").join(" ");

            return (
              <Fragment key={name}>
                <li>{separator}</li>
                {activeLink ? (
                  <div className="flex justify-between w-[100%]">
                    <li className="text-background-2 pt-[6px] capitalize">
                      {updatedName}
                    </li>
                    <IconButton
                      toggle={onToggleSideNav}
                      img={updown}
                      text={"Expand"}
                    />
                  </div>
                ) : (
                  <li>
                    <Link
                      to={`/${name}`}
                      className="text-link capitalize  text-[#999999]"
                    >
                      {updatedName}
                    </Link>
                  </li>
                )}
              </Fragment>
            );
          })}
        </ol>
      )}
    </>
  );
};

export default Breadcrumbs;
