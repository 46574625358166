import { Card, Button, LinearProgress } from "./ui";
import { Stack } from "./layout";
import eclipse from "../assets/icons/eclipse-icon.svg";
import google from "../assets/images/google.svg";
import avatar1 from "../assets/images/avatar-1.svg";
import avatar2 from "../assets/images/avatar-2.svg";
import avatar3 from "../assets/images/avatar-3.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Paths from "../constants/paths";
import { getAProject } from "../features/projects/projectSlice";

const ProjectCard = ({ project }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNavigate = (uuid) => {
    dispatch(getAProject(project.uuid)).then((res) => {
      // console.log("RESPONSE", res);
      if (res.payload.success) {
        return navigate(Paths.UserNewProjectPath, { state: { uuid } });
      }
    });
  };
  return (
    <Card className="px-6 pt-6 min-h-[284px] pb-5">
      <Stack className="mb-5" direction="row" justifyContent="between">
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={google} alt="" />
          <span className="text-lg text-background-2">{project.name}</span>
        </Stack>
        <img className="cursor-pointer" src={eclipse} alt="eclipse icon" />
      </Stack>
      <span className="text-sm text-background-2">{project.description}</span>
      <Stack className="gap-[14px]" direction="row" alignItems="center">
        <span className="font-light text-sm text-primary inline-block my-4">
          {project.noOfActiveTasks} Task Active
        </span>

        <div className="flex -space-x-4">
          <img
            className="w-8 h-8 border-2 border-white rounded-full"
            src={avatar1}
            alt="user 3 avatar"
          />
          <img
            className="w-8 h-8 border-2 border-white rounded-full"
            src={avatar2}
            alt="user 2 avatar"
          />
          <img
            className="w-8 h-8 border-2 border-white rounded-full"
            src={avatar3}
            alt="user 3 avatar"
          />
          <a
            className="flex items-center justify-center w-8 h-8 text-sm text-background-2 bg-[#F5F5F5] border-2 border-white rounded-full hover:bg-gray-600 hover:text-white"
            href="/#"
          >
            +4
          </a>
        </div>
      </Stack>
      <Stack
        className="mb-5"
        direction="row"
        justifyContent="between"
        alignItems="center"
      >
        <span className="text-xs text-background-2">
          <span className="text-[#B2B2B2]">Created </span>
          {project.date}
        </span>
        <Button
          className="text-primary"
          color="bg-primary-light-4"
          onClick={() => onNavigate(project.uuid)}
        >
          View project
        </Button>
      </Stack>
      <LinearProgress progressPercentage={70} />
    </Card>
  );
};

export default ProjectCard;
