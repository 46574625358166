import React from "react";
import DropWrapper from "../DropWrapper";
import BoardItem from "./BoardItem";

const SprintBoard = ({ sprint, onDrop, progressId }) => {
  //   console.log("PROJECT", project);
  return (
    <DropWrapper
      onDrop={onDrop}
      progressId={progressId}
      className=" w-[318px] h-[462px] border-[rgba(153, 153, 153, 0.5)] border-[1px] px-4 py-4 justify- flex flex-col bg-[#FCFCFC] mr-5"
    >
      <div className="flex justify-between items-center">
        <p className="text-[14px] font-medium">{sprint?.progressTitle}</p>
        <p className="font-extrabold cursor-pointer">+</p>
      </div>
      {/* <BoardItem item={board_item} /> */}

      {sprint?.progressTasks?.length > 0 &&
        sprint?.progressTasks?.map((el, i) => {
          return <BoardItem item={el} key={i} />;
        })}
    </DropWrapper>
  );
};

export default SprintBoard;
