import {
  HiOutlineDocumentText,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineQuestionMarkCircle,

} from 'react-icons/hi';
import { FiSettings, FiBox, FiEdit } from 'react-icons/fi';

import {
  UserDashboardPath,
  UserProjectsPath,
  UserAccountSetingsPath,
  UserWorkspacePath,
  UserProductReviewPath,
  UserSupportPath,
  LogoutPath,
} from './paths';

export const SidebarItems = [
  {
    icon: <HiOutlineHome />,
    path: UserDashboardPath,
    title: 'Dashboard',
  },

  {
    icon: <HiOutlineDocumentText />,
    path: UserProjectsPath,
    title: 'Projects',
  },
  {
    icon: <FiSettings />,
    path: UserAccountSetingsPath,
    title: 'Account Setting',
  },
  {
    icon: <FiBox />,
    path: UserWorkspacePath,
    title: 'Workspace',
  },
  {
    icon: <FiEdit />,
    path: UserProductReviewPath,
    title: 'Product Review',
  },

  {
    icon: <HiOutlineLogout />,
    path: LogoutPath,
    title: 'Log Out',
  },
  {
    icon: <HiOutlineQuestionMarkCircle />,
    path: UserSupportPath,
    title: 'Support',
  },
];
