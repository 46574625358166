import React from "react";
import { Button, Input, Spinner } from "../../components/ui";
import avatar from "../../assets/images/avatar.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../auth/authSlice";
import ChangePassword from "../../components/profile/ChangePassword";

const Profile = () => {
  const {
    auth: { userProfile, loading },
  } = useSelector((state) => state);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
      altEmail: userProfile?.altEmail,
      shortBio: userProfile?.shortBio,
    },
  });

  const dispatch = useDispatch();

  return (
    <>
      <div className="w-[100%] lg:w-[920px] lg:mt-8 pb-8 m-auto mb-7 lg:py-6 bg-white pl-2 lg:pl-6">
        <p className="text-[24px] pt-3 font-normal font-sans">Profile</p>
        <div className="w-[90%] lg:w-[856px] h-[0.5px] bg-[#CFCED1]"></div>
        <div className="flex items-center mt-11 mb-6 ">
          <div className="h-[28px] w-[28px] lg:h-[46px] lg:w-[46px] mb-5">
            <img className="w-full h-full" src={avatar} alt="profile picure" />
          </div>

          <label
            htmlFor="file-upload"
            className="text-[16px] ml-6 mr-8 text-center cursor-pointer rounded-md font-sans font-normal py-2 w-[72px] h-[38px] bg-white drop-shadow-lg"
          >
            <span className="mr-2">Upload</span>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              {...register("profilePicture")}
            />
          </label>
          {/* <p className="text-[16px] font-sans font-normal cursor-pointer text-[#87838C]">
            Remove
          </p> */}
        </div>
        <div>
          <p className="text-[14px] font-sans font-normal">Email Address</p>
          <p className="text-[14px] mb-4 font-sans font-normal">
            {userProfile?.email}
          </p>
        </div>
        <div className="flex flex-col lg:flex-row lg:flex-wrap gap-4">
          <div className="lg:w-[40%] w-[95%]">
            <Input
              label="First Name"
              type="text"
              placeholder="enter your name"
              register={register}
              name="firstName"
            />
          </div>

          <div className="lg:w-[40%] w-[95%]">
            <Input
              label="Last Name"
              type="text"
              placeholder="enter your name"
              register={register}
              name="lastName"
            />
          </div>
          <div className="lg:w-[40%] w-[95%]">
            <Input
              label="Alternative Email"
              type="email"
              placeholder="enter your name"
              register={register}
              name="altEmail"
            />
          </div>
          <div className="lg:w-[40%] w-[95%]">
            <Input
              label="Short Bio"
              type="text"
              placeholder="enter your name"
              register={register}
              name="shortBio"
            />
          </div>
        </div>
        <Button
          disabled={loading}
          size="large"
          color="bg-primary"
          className="text-white mt-10 bg-gradient-to-r from-primary to-primary-light mr-7"
          onClick={handleSubmit((data) => {
            console.log(data);
            if (data.profilePicture.length > 0) {
              dispatch(updateProfile(data));
            } else {
              const { profilePicture, ...rest } = data;
              dispatch(updateProfile(rest));
            }
          })}
        >
          {loading ? <Spinner /> : "Update"}
        </Button>
      </div>
      <ChangePassword />
    </>
  );
};

export default Profile;
